import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, {
  Dispatch,
  FC,
  MouseEvent,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { ReactComponent as WorldIcon } from '../../icons/World.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as GroupIcon } from '../../icons/Group.svg';
import styles from '../profile/PublicStatusPopover.module.css';
import { EClaimPrivacy } from '../profile/PublicStatusPopover';

type DefaultPublicStatusPopoverProps = {
  claimPrivacy: EClaimPrivacy;
  claim: string;
  setClaims: Dispatch<SetStateAction<{ gravatar: string[]; oauth: string[] }>>;
  claims: { gravatar: string[]; oauth: string[] };
};

export const DefaultPublicStatusPopover: FC<DefaultPublicStatusPopoverProps> = ({
  claimPrivacy,
  claim,
  setClaims,
  claims,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (event: MouseEvent<HTMLButtonElement | ReactElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleButtonClick = async (
    event: MouseEvent<HTMLButtonElement | ReactElement>,
    newPrivacy: EClaimPrivacy,
  ) => {
    closePopover(event);

    if (claimPrivacy === newPrivacy) return;

    const filteredOauthClaims = claims.oauth.filter((el) =>
      claim === 'family_name given_name' ? !'family_name given_name'.includes(el) : el !== claim,
    );
    const filteredGravatarClaims = claims.gravatar.filter((el) =>
      claim === 'family_name given_name' ? !'family_name given_name'.includes(el) : el !== claim,
    );
    switch (newPrivacy) {
      case EClaimPrivacy.private:
        setClaims({
          oauth: filteredOauthClaims,
          gravatar: filteredGravatarClaims,
        });
        break;
      case EClaimPrivacy.publicOauth:
        setClaims({
          oauth: [...new Set([...claims.oauth, claim])],
          gravatar: filteredGravatarClaims,
        });
        break;
      case EClaimPrivacy.publicGravatar:
        setClaims({
          oauth: [...new Set([...claims.oauth, claim])],
          gravatar: [...new Set([...claims.gravatar, claim])],
        });
    }
  };

  const GetPublicityTooltip = useCallback(() => {
    if (claimPrivacy === EClaimPrivacy.private)
      return (
        <Tooltip
          arrow
          title="Сделать приватным"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <LockIcon />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      );

    if (claimPrivacy === EClaimPrivacy.publicOauth)
      return (
        <Tooltip
          arrow
          title="Доступно по запросу"
          classes={{
            tooltip: styles['input-tooltip'],
            arrow: styles['input-tooltip-arrow'],
          }}
        >
          <IconButton
            onClick={(e) => {
              openPopover(e);
            }}
            className={styles.button}
          >
            <GroupIcon className={styles['public-icon']} />
            <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
          </IconButton>
        </Tooltip>
      );
    return (
      <Tooltip
        arrow
        title="Сделать доступным для всех"
        classes={{
          tooltip: styles['input-tooltip'],
          arrow: styles['input-tooltip-arrow'],
        }}
      >
        <IconButton
          onClick={(e) => {
            openPopover(e);
          }}
          className={styles.button}
        >
          <WorldIcon className={styles['public-icon']} />
          <ArrowDownIcon width={16} fill="#0B1641" className={styles['arrow-down']} />
        </IconButton>
      </Tooltip>
    );
  }, [claimPrivacy]);

  return (
    <div className={styles['lock-button']} style={{ marginRight: '0' }}>
      {GetPublicityTooltip()}
      <Popover
        classes={{
          paper: clsx(styles.paper),
        }}
        onClose={closePopover}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Button
          startIcon={<LockIcon />}
          className={clsx(styles['popover-button'], {
            [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.private,
          })}
          onClick={(e) => {
            handleButtonClick(e, EClaimPrivacy.private);
          }}
        >
          <div className={styles['popover-info']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>Сделать приватным</Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Будет приватным по умолчанию для новых пользователей
            </Typography>
          </div>
        </Button>
        <Button
          startIcon={<GroupIcon />}
          className={clsx(styles['popover-button'], {
            [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.publicOauth,
          })}
          onClick={(e) => {
            handleButtonClick(e, EClaimPrivacy.publicOauth);
          }}
        >
          <div className={styles['popover-info']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              Сделать доступным по запросу
            </Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Будет публичным по умолчанию для новых пользователей
            </Typography>
          </div>
        </Button>
        <Button
          startIcon={<WorldIcon />}
          className={clsx(styles['popover-button'], {
            [styles['selected-popover-button']]: claimPrivacy === EClaimPrivacy.publicGravatar,
          })}
          onClick={(e) => {
            handleButtonClick(e, EClaimPrivacy.publicGravatar);
          }}
        >
          <div className={styles['popover-info']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              Сделать доступным для всех
            </Typography>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              Будет публичным по умолчанию для новых пользователей
            </Typography>
          </div>
        </Button>
      </Popover>
    </div>
  );
};
