import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TSettings } from './client';

export enum TargetType {
  user = 'USER',
  client = 'CLIENT',
  provider = 'PROVIDER',
}

export type TRule = {
  id: number;
  target: TargetType;
  field_name: string;
  default?: string;
  editable: boolean;
  required: boolean;
  created_at: string;
  updated_at: string;
  // validations: [];
}

export type TRules = TRule[]

export type TCreateRule = {
  target?: TargetType;
  field_name: string;
  default?: string;
  editable?: boolean;
  required?: boolean;
}

export type TUpdateRule = {
  default?: string;
  editable?: boolean;
  required?: boolean;
}

export type TProfileField = { field: string; title: string };
export type TProfileFields = TProfileField[];

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  tagTypes: [
    'Settings',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/settings`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<TSettings, void>({
      query: () => '',
    }),

    editSettings: builder.mutation<void, TSettings>({
      query: (body) => ({ method: 'PUT', body, url: '' }),
    }),

    getRules: builder.query<TRules, void>({
      query: () => 'rules',
    }),

    getProfileFields: builder.query<TProfileFields, void>({
      query: () => 'profile_fields',
    }),

    updateRule: builder.mutation<void, { id: number, body: TUpdateRule }>({
      query: ({ id, body }) => ({ method: 'PUT', body, url: `rules/${id}` }),
    }),

    createRule: builder.mutation<void, TCreateRule>({
      query: (body) => ({ method: 'POST', body, url: 'rules' }),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useEditSettingsMutation,
  useGetRulesQuery,
  useGetProfileFieldsQuery,
  useUpdateRuleMutation,
  useCreateRuleMutation,
} = settingsApi;
