import Button from '@mui/material/Button';
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useState,
  // #371 KeyboardEvent,
  useRef,
} from 'react';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import clsx from 'clsx';
import styles from './CreateApplication.module.css';
import Typography from '@mui/material/Typography';
import { UploadAndDisplayImage } from '../UploadAndDisplayImage';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateApplicationMutation } from '../../redux/services/client';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { DescriptionField, RedirectUrisField } from './EditApplicationFields';
// #371 import Switch from '@mui/material/Switch';
import { setApplicationFormChanged } from '../../redux/appSlice';
import Modal from '@mui/material/Modal';
import { /* #371 getDeclinationByNumber,*/ isObjectEmpty, isUrl } from '../../helpers';
import { CustomSelect } from '../custom/CustomSelect';
import { useNavigate } from 'react-router-dom-v5-compat';
// #371 import { ACCESS_TOKEN_TTL } from '../../constants';

type Inputs = {
  name: string;
  description: string;
  domain: string;
  redirectUri: {
    name: string;
    value: string;
  }[];
  logoutUri: {
    name: string;
    value: string;
  }[];
  request_uris: {
    name: string;
    value: string;
  }[];
  response_types: string[];
  grant_types: string[];
  // #371 refresh_token_ttl: number;
  // #371 access_token_ttl: number;
  avatar: File | null;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
};

export enum EAuthMethodType {
  client_secret_basic = 'client_secret_basic',
  client_secret_post = 'client_secret_post',
  client_secret_jwt = 'client_secret_jwt',
  private_key_jwt = 'private_key_jwt',
  none = 'none',
}

export enum ESigningAlgTypes {
  RS256 = 'RS256',
  PS256 = 'PS256',
}

export enum ESubjectTypeVariant {
  public = 'public',
  pairwise = 'pairwise',
}

export enum EResponseTypes {
  code_token = 'code token',
  code_id_token_token = 'code id_token token',
  code_id_token = 'code id_token',
  code = 'code',
  id_token = 'id_token',
  none = 'none',
}

export enum EGrantTypes {
  authorization_code = 'authorization_code',
  implicit = 'implicit',
  refresh_token = 'refresh_token',
  device_flow = 'urn:ietf:params:oauth:grant-type:device_code',
}

export const redirectUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
  // #520
  // .url('Неверный формат ссылки')
  // .matches(/^(http|https)/, {
  //   excludeEmptyString: true,
  //   message: 'Адрес должен начинаться с http:// или https://',
  // }),
});

export const logoutUriSchema = yup.object({
  value: yup
    .string()
    // #520 .url('Неверный формат ссылки')
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
});

export const requestUriSchema = yup.object({
  value: yup
    .string()
    .max(2000, 'Ссылка не может превышать 2000 символов')
    .test('is-url', 'Неверный формат ссылки', (value?: string) => {
      if (!value) return true;
      return isUrl(value);
    }),
});

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255).min(0),
    domain: yup
      .string()
      // #520 .url('Неверный формат ссылки')
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirectUri: yup.array().of(redirectUriSchema).required(),
    logoutUri: yup.array().of(logoutUriSchema).required(),
    request_uris: yup.array().of(requestUriSchema).required(),
    // #371
    // refresh_token_ttl: yup
    //   .number()
    //   .min(1800, 'Время жизни токена токена обновления не может быть меньше 1800 секунд')
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    // access_token_ttl: yup
    //   .number()
    //   .min(60, 'Время жизни токена доступа не может быть меньше 60 секунд')
    //   .max(
    //     ACCESS_TOKEN_TTL,
    //     `Время жизни токена доступа не может превышать ${ACCESS_TOKEN_TTL} секунд` +
    //       (ACCESS_TOKEN_TTL / 60 / 60 / 24 >= 1
    //         ? ` (${
    //             ACCESS_TOKEN_TTL / 60 / 60 / 24 +
    //             ' ' +
    //             getDeclinationByNumber(ACCESS_TOKEN_TTL / 60 / 60 / 24, ['день', 'дня', 'дней'])
    //           }).`
    //         : ''),
    //   )
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

const CreateApplicationComponent: FC<{ userId?: string }> = ({ userId }) => {
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  // #371 const [showRefreshTokenInput, setShowRefreshTokenInput] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const savedCallback = useRef<() => void>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );
  const navigate = useNavigate();
  const [createApplication, result] = useCreateApplicationMutation();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    trigger,
    getValues,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      avatar: null,
      description: '',
      domain: '',
      redirectUri: [{ value: '' }],
      logoutUri: [{ value: '' }],
      request_uris: [{ value: '' }],
      response_types: [EResponseTypes.code],
      id_token_signed_response_alg: ESigningAlgTypes.RS256,
      introspection_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      revocation_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      token_endpoint_auth_method: EAuthMethodType.client_secret_basic,
      subject_type: ESubjectTypeVariant.public,
      grant_types: [EGrantTypes.authorization_code],
      require_auth_time: false,
      require_signed_request_object: false,
      // #371 refresh_token_ttl: 86400,
      // #371 access_token_ttl: 1800,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    fields: requestUris,
    append: requestAppend,
    remove: requestRemove,
  } = useFieldArray({
    control,
    name: 'request_uris',
  });
  const watchResponseTypes = watch('response_types');
  const watchGrantTypes = watch('grant_types');

  useEffect(() => {
    if (result.isSuccess) navigate('/applications');
  }, [result]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (Array.isArray(field))
          return field.some((elem) => (typeof elem === 'object' ? elem.value : elem));
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  const closeSaveModal = () => setSaveModalOpen(false);

  const {
    fields: redirectUris,
    append: redirectAppend,
    remove: redirectRemove,
  } = useFieldArray({
    control,
    name: 'redirectUri',
  });

  const {
    fields: logoutUris,
    append: logoutAppend,
    remove: logoutRemove,
  } = useFieldArray({
    control,
    name: 'logoutUri',
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    let duplicateRedirectUri;

    if (data.redirectUri.every((uri) => !uri.value)) {
      setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;

    data.redirectUri.reduce((acc: string[], item, index) => {
      if (acc.includes(item.value)) {
        setError(`redirectUri.${index}.value`, {
          message: 'Поле с таким адресом уже существует',
        });
        duplicateRedirectUri = true;
      }
      acc.push(item.value);
      return acc;
    }, [] as string[]);

    if (duplicateRedirectUri) return;
    const { redirectUri, logoutUri, request_uris, ...restData } = data;
    createApplication({
      user_id: userId,

      redirect_uris: redirectUri.map((uri) => uri.value).filter((uri) => !!uri),
      post_logout_redirect_uris: logoutUri.map((uri) => uri.value).filter((uri) => !!uri),
      request_uris: request_uris.map((uri) => uri.value).filter((uri) => !!uri),
      ...restData,
    });
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          if (applicationFormChanged) {
            savedCallback.current = () => navigate('/applications');
            return setSaveModalOpen(true);
          }
          navigate('/applications');
        }}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложения
      </Button>
      <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
        <div className={styles['padding-wrapper']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Создать приложение
          </Typography>
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Основная информация
          </Typography>
          <Typography
            className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
          >
            Название приложения
          </Typography>
          <TextField
            {...register('name', {
              onBlur: (event: FocusEvent<HTMLInputElement>) => {
                setValue('name', event.target.value.trim());
              },
              onChange: () => {
                if (errors.name) clearErrors('name');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Имя приложения, отображаемое пользователям
          </Typography>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Описание приложения
          </Typography>
          <TextField
            {...register('description', {
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length > 255) {
                  setError('description', {
                    message: 'Невозможно ввести более 255 символов',
                    type: 'validate',
                  });
                  setValue('description', event.target.value.slice(0, 255));
                } else {
                  clearErrors('description');
                }
              },
            })}
            className="custom"
            fullWidth
            variant="standard"
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ''}
            multiline
          />
          <DescriptionField control={control} />
          <div>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Логотип приложения
            </Typography>
            <UploadAndDisplayImage
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              componentName="create-client"
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              defaultValue={null}
              setAvatarValue={setAvatarValue}
              DefaultIcon={<AppIcon fill="#ced0d9" />}
            />
            {errors.avatar && (
              <Typography className={clsx('text-14', styles['input-error'])}>
                {errors.avatar.message}
              </Typography>
            )}
            <Typography className={clsx('text-14', 'color-858BA0')}>
              Файл с расширением .jpg, .jpeg, .png, .svg. Максимальный размер - 1 МБ.
            </Typography>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles['padding-wrapper']}>
          <Typography
            className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
          >
            Параметры приложения
          </Typography>
          <Typography
            className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
          >
            Адрес приложения
          </Typography>
          <TextField
            {...register('domain', {
              onChange: () => {
                if (errors.domain) clearErrors('domain');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.domain}
            helperText={errors.domain ? errors.domain.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Адрес приложения в формате «протокол://доменное имя:порт»
          </Typography>
          <>
            {redirectUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <Typography
                    className={clsx(
                      'text-14',
                      'color-0B1641',
                      styles.asterisk,
                      styles['input-title'],
                    )}
                  >
                    Возвратный URL #{index + 1} (Redirect_uri)
                  </Typography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`redirectUri.${index}.value`, {
                        onChange: () => {
                          if (errors?.redirectUri?.[index])
                            clearErrors(`redirectUri.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      FormHelperTextProps={{
                        className: clsx('text-14', 'color-858BA0'),
                      }}
                      onBlur={() => {
                        if (getValues('redirectUri').every((uri) => !uri.value))
                          setError(`redirectUri.0.value`, { message: 'Обязательное поле' });
                        else {
                          if (errors?.redirectUri?.[0]) clearErrors(`redirectUri.0.value`);
                          trigger(`redirectUri.${index}.value`);
                        }
                      }}
                      error={!!errors.redirectUri?.[index]}
                      helperText={
                        errors.redirectUri ? errors?.redirectUri?.[index]?.value?.message : ''
                      }
                      variant="standard"
                    />
                    {redirectUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => redirectRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        name="redirectUri"
                        control={control}
                        onClick={() => redirectAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                    >
                      Адрес, на который пользователь переадресовывается после авторизации
                    </Typography>
                  )}
                </div>
              );
            })}
            {redirectUris.length > 1 && (
              <RedirectUrisField
                name="redirectUri"
                control={control}
                onClick={() => redirectAppend({ value: '', name: '' })}
                className={clsx(styles['add-button'], styles['add-button-bottom'])}
              />
            )}
          </>
          <>
            {logoutUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    URL выхода #{index + 1} (post_logout_redirect_uri)
                  </Typography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`logoutUri.${index}.value`, {
                        required: true,
                        onChange: () => {
                          if (errors?.logoutUri?.[index]) clearErrors(`logoutUri.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      FormHelperTextProps={{
                        className: clsx('text-14', 'color-858BA0', styles['helper-text']),
                      }}
                      error={!!errors.logoutUri?.[index]}
                      helperText={
                        errors.logoutUri ? errors?.logoutUri?.[index]?.value?.message : ''
                      }
                      variant="standard"
                    />
                    {logoutUris.length > 1 ? (
                      <Button
                        variant="custom"
                        color="secondary"
                        onClick={() => logoutRemove(index)}
                        className={clsx(styles['delete-button'])}
                      >
                        Удалить
                      </Button>
                    ) : (
                      <RedirectUrisField
                        name="logoutUri"
                        control={control}
                        onClick={() => logoutAppend({ value: '', name: '' })}
                        className={styles['add-button']}
                      />
                    )}
                  </div>
                  {index === 0 && (
                    <Typography
                      className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}
                      style={{ width: '85%' }}
                    >
                      Адрес, на который переадресовывается пользователь после выхода. Если значение
                      не указано, то используется «Возвратный URL»
                    </Typography>
                  )}
                </div>
              );
            })}
            {logoutUris.length > 1 && (
              <RedirectUrisField
                name="logoutUri"
                control={control}
                onClick={() => logoutAppend({ value: '', name: '' })}
              />
            )}
          </>
          <>
            {requestUris.map((uri, index) => {
              return (
                <div key={uri.id}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    URL запроса аутентификации или восстановления после аутентификации #{index + 1}{' '}
                    (request_uris)
                  </Typography>
                  <div className={styles['field-item']}>
                    <TextField
                      {...register(`request_uris.${index}.value`, {
                        onChange: () => {
                          if (errors.request_uris?.[index])
                            clearErrors(`request_uris.${index}.value`);
                        },
                      })}
                      className={clsx('custom', styles['add-text-field'])}
                      FormHelperTextProps={{
                        className: clsx('text-14', 'color-858BA0', styles['helper-text']),
                      }}
                      error={!!errors.request_uris?.[index]}
                      helperText={
                        errors.request_uris ? errors?.request_uris?.[index]?.value?.message : ''
                      }
                      variant="standard"
                    />
                    <Button
                      variant="custom"
                      color="secondary"
                      onClick={() => {
                        if (requestUris.length > 1) requestRemove(index);
                        else setValue('request_uris', [{ value: '', name: 'request_uris' }]);
                      }}
                      className={clsx(styles['delete-button'])}
                    >
                      Удалить
                    </Button>
                  </div>
                </div>
              );
            })}
            <RedirectUrisField
              control={control}
              onClick={() => requestAppend({ value: '', name: '' })}
              className={clsx(styles['add-button'], styles['add-button-bottom'])}
              name="request_uris"
            />
          </>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            response_types
          </Typography>
          <div className={styles['type-buttons-wrapper']}>
            {Object.values(EResponseTypes).map((type) => (
              <Button
                variant={
                  watchResponseTypes.find((findType) => findType === type)
                    ? 'contained'
                    : 'outlined'
                }
                className={styles.typeButton}
                disabled={
                  type === EResponseTypes.code || type === EResponseTypes.none
                    ? false
                    : !watchGrantTypes.includes(EGrantTypes.implicit)
                }
                onClick={() => {
                  setValue(
                    'response_types',
                    watchResponseTypes.find((findType) => findType === type)
                      ? watchResponseTypes.filter((filterType) => filterType !== type)
                      : [...watchResponseTypes, type],
                    { shouldDirty: true },
                  );
                }}
                key={type}
              >
                {type}
              </Button>
            ))}
          </div>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            grant_types
          </Typography>
          <div className={styles['type-buttons-wrapper']}>
            {Object.values(EGrantTypes).map((type) => (
              <Button
                className={styles.typeButton}
                variant={
                  watchGrantTypes.find((findType) => findType === type) ? 'contained' : 'outlined'
                }
                onClick={() => {
                  const newGrantTypes = watchGrantTypes.find((findType) => findType === type)
                    ? watchGrantTypes.filter((filterType) => filterType !== type)
                    : [...watchGrantTypes, type];
                  if (!newGrantTypes.includes(EGrantTypes.implicit))
                    setValue(
                      'response_types',
                      watchResponseTypes.filter(
                        (type) => type === EResponseTypes.code || type === EResponseTypes.none,
                      ),
                    );

                  setValue('grant_types', newGrantTypes, { shouldDirty: true });
                }}
                key={type}
              >
                {type}
              </Button>
            ))}
          </div>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Метод аутентификации клиента для конечной точки получения токена
            (token_endpoint_auth_method)
          </Typography>
          <Controller
            control={control}
            name="token_endpoint_auth_method"
            render={({ field }) => (
              <CustomSelect
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Метод аутентификации, используемый при доступе к конечной точке проверки токена
            (introspection_endpoint_auth_method)
          </Typography>
          <Controller
            control={control}
            name="introspection_endpoint_auth_method"
            render={({ field }) => (
              <CustomSelect
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Метод аутентификации, используемый при доступе к конечной точке отзыва токенов
            (revocation_endpoint_auth_method)
          </Typography>
          <Controller
            control={control}
            name="revocation_endpoint_auth_method"
            render={({ field }) => (
              <CustomSelect
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...EAuthMethodType }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Алгоритм подписи, используемый при создании подписанного ID-токена
            (id_token_signed_response_alg)
          </Typography>
          <Controller
            control={control}
            name="id_token_signed_response_alg"
            render={({ field }) => (
              <CustomSelect
                style={{ width: '100%' }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {Object.keys({ ...ESigningAlgTypes }).map((variant) => (
                  <MenuItem key={variant} value={variant} className="custom-select">
                    {variant}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          <div className={styles['switch-wrapper']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>require_auth_time</Typography>
            <Controller
              control={control}
              name="require_auth_time"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <div className={styles['switch-wrapper']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              require_signed_request_object
            </Typography>
            <Controller
              control={control}
              name="require_signed_request_object"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
            Способ передачи ID пользователя в идентификационном токене (subject_type)
          </Typography>
          <Controller
            control={control}
            name="subject_type"
            defaultValue={ESubjectTypeVariant.public}
            render={({ field }) => (
              <CustomSelect
                style={{ width: '100%', marginBottom: 32 }}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              >
                {['public', 'pairwise'].map((type) => (
                  <MenuItem key={type} value={type} className="custom-select">
                    {type}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {/* #371
          <div className={styles['switch-wrapper']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              Ограничить время действия токена обновления (refresh_token)
            </Typography>
            <Switch
              value={showRefreshTokenInput}
              onChange={() => setShowRefreshTokenInput((shown) => !shown)}
              disableRipple
            />
          </div>

          {showRefreshTokenInput && (
            <>
              <Typography
                className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
              >
                Время действия токена обновления (refresh_token)
              </Typography>
              <div style={{ position: 'relative' }}>
                <TextField
                  {...register('refresh_token_ttl', {
                    onChange: () => {
                      if (errors.refresh_token_ttl) clearErrors('refresh_token_ttl');
                    },
                  })}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key.toLowerCase() === 'e') e.preventDefault();
                  }}
                  type="number"
                  className={clsx(styles['token-textfield'], 'custom')}
                  InputProps={{
                    className: styles['token-input'],
                  }}
                  FormHelperTextProps={{
                    className: clsx('text-14', 'color-858BA0'),
                  }}
                  error={!!errors.refresh_token_ttl}
                  helperText={errors.refresh_token_ttl ? errors.refresh_token_ttl.message : ''}
                  fullWidth
                  variant="standard"
                />
                <Typography
                  className={clsx('text-14', 'color-858BA0')}
                  style={{ position: 'absolute', right: 40, top: 10 }}
                >
                  секунд
                </Typography>
              </div>
            </>
          )}
          <Typography
            className={clsx(
              'text-14',
              'color-0B1641',
              styles.asterisk,
              styles['input-title'],
              styles['input-subtitle'],
            )}
          >
            Время действия авторизационного токена (access_token)
          </Typography>
          <div style={{ position: 'relative' }}>
            <TextField
              {...register('access_token_ttl', {
                onChange: () => {
                  if (errors.access_token_ttl) clearErrors('access_token_ttl');
                },
              })}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key.toLowerCase() === 'e') e.preventDefault();
              }}
              type="number"
              className={clsx('custom', styles['token-textfield'])}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
            />
            <Typography
              className={clsx('text-14', 'color-858BA0')}
              style={{ position: 'absolute', right: 40, top: 10 }}
            >
              секунд
            </Typography>
          </div>
          {!!errors.access_token_ttl && (
            <Typography className={clsx('text-14', styles['input-error'])}>
              {errors.access_token_ttl.message}
            </Typography>
          )} */}
          <div className={styles['submit-buttons']}>
            <Button
              onClick={() => {
                if (applicationFormChanged) {
                  savedCallback.current = () => navigate(-1);
                  return setSaveModalOpen(true);
                }
                navigate(-1);
              }}
              variant="custom"
              color="secondary"
            >
              Отмена
            </Button>
            <Button className={styles['create-button']} type="submit" variant="custom">
              Создать
            </Button>
          </div>
        </div>
      </form>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                savedCallback.current?.();
                dispatch(setApplicationFormChanged(false));
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const CreateApplication = connect(mapStateToProps)(CreateApplicationComponent);
