import React, { useState, FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import styles from './ProviderProfile.module.css';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { ReactComponent as SearchIcon } from '../../icons/Search.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import ListItem from '@mui/material/ListItem';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { getImageURL, randomString } from '../../helpers';
import {
  EGetProviderAction,
  MiscProviderType,
  OauthProviderType,
  TMiscProvider,
  TOauthProvider,
  UniqueProviderType,
  useGetProvidersQuery,
} from '../../redux/services/provider';
import { getAccessToken } from '../../service/auth';
import { setNotice } from '../../redux/noticesSlice';
import Cookies from 'universal-cookie';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import Avatar from '@mui/material/Avatar';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { useBindEthereumAccountMutation, userApi } from '../../redux/services/user';
import { useLazyGetNonceQuery } from '../../redux/services/ethereum';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom-v5-compat';

const mapStateToProps = (state: RootState) => ({
  userAvatar: state.user.userProfile.picture,
  userId: state.user.userProfile.id,
  userNickname: state.user.userProfile.nickname,
});

type TProviderProfileComponent = {
  userAvatar?: string | null;
  userId?: string;
  userNickname?: string;
};

export const ProviderProfileComponent: FC<TProviderProfileComponent> = ({
  userId,
  userAvatar,
  userNickname,
}) => {
  const { data: providers } = useGetProvidersQuery({
    client_id: CLIENT_ID,
    onlyActive: true,
    action: EGetProviderAction.add,
  });
  const [bindEtereumAccount] = useBindEthereumAccountMutation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [defaultProviders, setDefaultProviders] = useState(providers);
  const [providerModal, setProviderModal] = useState(false);
  const [externalAccountOwner, setExternalAccountOwner] = useState('');
  // #984 const userProfile = useSelector((state: RootState) => state.user.userProfile);
  const windowRef = useRef<Window | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let intervalId: ReturnType<typeof setTimeout>;
  const cookies = new Cookies();
  const [providerTemplate, setProviderTemplate] = useState({
    avatar: '',
    client_id: '',
    url: '',
    type: 'EMAIL',
    scopes: '',
    name: '',
    pathToAvatar: '',
    provider_id: '',
    redirect_uri: `${BACKEND_URL}/api/interaction/code`,
  });
  const [getNonce] = useLazyGetNonceQuery();

  const externalAccount = async (
    providerUrl: string,
    clientId: string,
    providerId: string,
    providerScopes?: string,
    rebind?: boolean,
  ) => {
    const access = await getAccessToken();
    cookies.set('provider_id', providerId, { path: '/api/interaction/code' });
    cookies.set('client_id', CLIENT_ID, { path: '/api/interaction/code' });
    cookies.set('access_token', access, { path: '/api/interaction/code' });
    cookies.set('user_id', userId, { path: '/api/interaction/code' });
    cookies.set('rebind', rebind, { path: '/api/interaction/code' });
    const url = `${providerUrl}?client_id=${encodeURIComponent(
      clientId,
    )}&response_type=code&scope=${providerScopes}&state=${encodeURIComponent(
      'security_token:' + randomString(30),
    )}&redirect_uri=${encodeURIComponent(BACKEND_URL + '/api/interaction/code')}`;
    windowRef.current = window.open(
      url,
      '_blank',
      ` left=${screen.width}, top=` + (screen.height - 470) / 2 + ` width=500, height=500`,
    );

    intervalId = setInterval(() => {
      const urlSearchParams = new URLSearchParams(windowRef.current?.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.result === 'true') {
        clearInterval(intervalId);
        windowRef.current?.close();
        dispatch(userApi.util.invalidateTags(['ExternalAccounts']));
        navigate('/profile');
      } else if (params.result === 'false') {
        clearInterval(intervalId);
        windowRef.current?.close();
        setProviderModal(false);
        switch (params.cause) {
          case 'binded_to_this_user': {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Аккаунт уже привязан к этому профилю.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          case 'binded_to_blocked_user': {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message:
                  'Не удалось привязать внешний аккаунт: он привязан к заблокированному аккаунту.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          case 'binded_to_another_user': {
            return setExternalAccountOwner(`oauth:${params.nickname}`);
          }

          default:
            dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Ошибка привязки',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
            setExternalAccountOwner('');
        }
      } else if (windowRef.current?.closed) {
        dispatch(
          setNotice({
            error: 'Ошибка',
            id: Math.random(),
            isRead: false,
            message: 'Операция прервана пользователем',
            timestamp: new Date().toString(),
          }),
        );
        setProviderModal(false);
        setExternalAccountOwner('');
        clearInterval(intervalId);
      }
    }, 250);
  };

  const authByEthereum = async (rebind?: boolean) => {
    if (window.ethereum) {
      const address = await getEthereumAddress();
      if (address) {
        const { data } = await getNonce(address);

        if (userId && data?.nonce) {
          const sign = await getEthereumSign(address, data?.nonce);
          const bindEthereumAccountResponse = await bindEtereumAccount({
            userId,
            address,
            signature: sign,
            rebind,
            client_id: CLIENT_ID,
          }).unwrap();

          if (bindEthereumAccountResponse.binded_to_this_user) {
            setExternalAccountOwner('');
            windowRef.current?.close();
            return dispatch(
              setNotice({
                error: 'Ошибка',
                id: Math.random(),
                isRead: false,
                message: 'Аккаунт уже привязан к этому профилю.',
                statusCode: 400,
                timestamp: new Date().toString(),
              }),
            );
          }

          if (!bindEthereumAccountResponse.success)
            return setExternalAccountOwner(`ethereum:${bindEthereumAccountResponse.nickname}`);
        }
      }
      navigate('/profile');
    } else {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message: 'Установите Metamask',
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const getEthereumAddress = async (): Promise<string | undefined> => {
    try {
      await window?.ethereum?.request({ method: 'eth_requestAccounts' });
      const accounts = await window?.ethereum?.request({ method: 'eth_accounts' });

      if (accounts?.length) return accounts[0];
      return undefined;
    } catch (e) {
      console.log('getEthereumAddress error: ', e);
    }
  };

  const getEthereumSign = async (address: string, nonce: string) => {
    try {
      const hashedMessage = `0x${Buffer.from(
        'Чтобы авторизоваться в сервисе ' +
          PROJECT_NAME +
          ', подпишите случайно сгенерированный текст: ' +
          nonce,
        'utf8',
      ).toString('hex')}`;

      const sign = await window?.ethereum?.request({
        method: 'personal_sign',
        params: [hashedMessage, address, ''],
      });

      return sign;
    } catch (e) {
      console.log('getEthereumSign error: ', e);
    }
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const filteredProviders = providers?.filter((provider) => {
      return provider.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setDefaultProviders(filteredProviders);
  }, [searchValue]);

  const closeWindow = () => {
    setProviderModal(false);
    windowRef.current?.close();
  };

  const closeRebindModal = () => setExternalAccountOwner('');

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['profile-provider']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Добавить способ входа
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-3C4567', 'text-14')}>
          Выберите способ входа, который хотите добавить в свой профиль:
        </Typography>
        {providers?.length && providers.length >= 10 && (
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ marginTop: 8 }}
            className={clsx(styles.search, 'custom')}
            variant="standard"
            placeholder="Поиск"
            sx={{ '& .Mui-focused svg': { left: 12 } }}
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
        )}
        <div className={styles.providers}>
          {/* #439 {(defaultProviders || providers)?.map((provider) => { */}
          {(defaultProviders || providers)
            ?.filter((provider) => {
              // #984
              if ((provider as TMiscProvider).unique_type === UniqueProviderType.PHONE) return;
              // #984
              // if (
              //   userProfile.phone_number &&
              //   (provider as TMiscProvider).unique_type === UniqueProviderType.PHONE
              // )
              //   return false;
              return ![MiscProviderType.CREDENTIALS, MiscProviderType.QRCODE].includes(
                provider.type as MiscProviderType,
              );
            })
            ?.map((provider) => {
              return (
                <ListItem
                  key={provider.id}
                  className={styles.provider}
                  onClick={() => {
                    // #984
                    // if ((provider as TMiscProvider).unique_type === UniqueProviderType.PHONE)
                    //   return navigate('/profile/phone/add');
                    if (provider.type === 'EMAIL') {
                      navigate('/profile/email/add');
                      // #439
                      // } else if (provider.type === 'CREDENTIALS') {
                      //   return;
                    } else if (provider.type === MiscProviderType.SMS) {
                      navigate(`/profile/sms/add/${provider.id}`);
                    } else if (
                      provider.type === 'LDAP' ||
                      provider.type === MiscProviderType.ALDPRO
                    ) {
                      navigate(`/profile/ldap/add/${provider.id}`);
                    } else if (provider.type === MiscProviderType.IDM) {
                      navigate(`/profile/idm/add/${provider.id}`);
                    } else if (provider.type === 'Provider1C') {
                      navigate(`/profile/1c/add/${provider.id}`);
                    } else if (provider.type === MiscProviderType.ETHEREUM) {
                      authByEthereum();
                    } else if (provider.type === MiscProviderType.KLOUD) {
                      navigate(`/profile/kloud/add/${provider.id}`);
                    } else if ((provider.type || '') in OauthProviderType) {
                      const providerCopy = { ...provider } as TOauthProvider;
                      setProviderTemplate({
                        ...providerTemplate,
                        url: providerCopy.authorization_endpoint,
                        type: providerCopy.type,
                        client_id: providerCopy.external_client_id,
                        provider_id: providerCopy.id,
                        scopes: providerCopy.scopes || '',
                        name: providerCopy.name,
                      });
                      setProviderModal(true);
                      externalAccount(
                        (provider as TOauthProvider).authorization_endpoint,
                        (provider as TOauthProvider).external_client_id,
                        (provider as TOauthProvider).id,
                        (provider as TOauthProvider).scopes,
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {provider.type === 'EMAIL' ? <MailIcon /> : !provider.avatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                </ListItem>
              );
            })}
        </div>
      </div>
      <Modal onClose={closeWindow} open={providerModal}>
        <div className={styles['provider-modal']}>
          <div className={styles['provider-modal-header']}>
            {userAvatar ? (
              <div
                style={{
                  backgroundImage: `url(${getImageURL(userAvatar)})`,
                  borderRadius: '50%',
                }}
                className={styles['provider-icon-modal']}
              />
            ) : (
              <Avatar className={styles['provider-icon-modal']}>
                <AvatarIcon />
              </Avatar>
            )}
            <div
              style={{
                backgroundImage: `url(${BACKEND_URL + '/' + providerTemplate.avatar})`,
              }}
              className={styles['provider-icon-modal']}
            >
              {!providerTemplate.avatar && <IdIcon />}
            </div>
            <IconButton onClick={closeWindow} className={styles['provider-close-modal']}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography className={styles['provider-modal-content']}>
            Войдите в аккаунт {providerTemplate.name} и подтвердите добавление аккаунта в профиль{' '}
            {userNickname}.
          </Typography>
        </div>
      </Modal>
      <Modal open={!!externalAccountOwner} onClose={closeRebindModal}>
        <div className={styles['rebind-provider-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeRebindModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Внешний аккаунт уже привязан к аккаунту {externalAccountOwner.split(':')[1]}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeRebindModal}>
              Отмена
            </Button>
            <Button
              onClick={() =>
                externalAccountOwner.split(':')[0] === 'ethereum'
                  ? authByEthereum(true)
                  : externalAccount(
                      providerTemplate.url,
                      providerTemplate.client_id,
                      providerTemplate.provider_id,
                      providerTemplate.scopes,
                      true,
                    )
              }
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Перепривязать
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const ProviderProfile = connect(mapStateToProps)(ProviderProfileComponent);
