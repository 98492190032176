import { Drawer, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './PublicProfile.module.css';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as DownloadIcon } from '../../icons/Download.svg';
import { getPublicProfile, getVCard, IPublicProfile } from '../../requests/user';
import { formatDate, getImageURL } from '../../helpers';
import { ExternalAccount } from './ExternalAccount';
import { TExternalAccount } from '../../redux/services/user';
import { useDispatch } from 'react-redux';
import { setNotice } from '../../redux/noticesSlice';

/**
 * Всплывающее окно с публичным профилем пользователя
 * @param param0
 * @returns
 */
export const PublicProfile = ({
  userEmail,
  isOpen,
  close,
}: {
  userEmail: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const dispatch = useDispatch();
  const [loadingVCard, setLoadingVCard] = useState(false);
  const [publicProfile, setPublicProfile] = useState<IPublicProfile | undefined>(undefined);
  const linesExternalAccounts: (JSX.Element | null | undefined)[] = [];
  const linesInfo: (JSX.Element | null | undefined)[] = [];

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const fetchPublicProfile = async () => {
      const profile = await getPublicProfile(userEmail);
      setPublicProfile(profile);
    };
    fetchPublicProfile();
  }, [isOpen]);

  if (!publicProfile) {
    return null;
  }

  // Генерация всех строчек профиля и external accounts
  Object.keys(publicProfile).forEach((key) => {
    // Пропускаем ключи, которые не нужно отображать, так как они отображаются отдельно
    if (key === 'email' || key === 'picture') {
      return;
    }

    // Обработка внешних аккаунтов
    if (key === 'ExternalAccount') {
      const externalAccounts = publicProfile[key] as unknown as Array<TExternalAccount>;
      if (externalAccounts) {
        externalAccounts.forEach((externalAccount) => {
          linesExternalAccounts.push(
            <ExternalAccount
              public_accounts_claims_gravatar={undefined}
              public_accounts_claims_oauth={undefined}
              userProfile={{ email: userEmail, id: '' }}
              account={externalAccount}
              withoutButtons={true}
            />,
          );
        });
      }
      return;
    }

    // Обработка остальных данных
    let value = publicProfile[key];
    // Проверяем, что значение существует и не пустая строка
    if (value) {
      if (key === 'birthdate' && typeof value === 'string') {
        value = formatDate(value);
      }

      linesInfo.push(
        <Typography key={key} className={clsx('text-14', styles['info-item-value'])}>
          {value}
        </Typography>,
      );
    }

    return;
  });

  // Получение vCard файла
  const handleVCardDownload = async () => {
    try {
      setLoadingVCard(true);
      const vCardBlob = await getVCard(userEmail); // Убедитесь, что getVCard возвращает Blob
      if (vCardBlob) {
        const url = window.URL.createObjectURL(vCardBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf'; // Имя файла, которое будет предложено пользователю
        document.body.appendChild(a); // Добавляем элемент в документ для корректной работы в Firefox
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a); // Удаляем элемент после скачивания
      }
    } catch (error) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: 'Ошибка при скачивании vCard',
          timestamp: new Date().toString(),
        }),
      );
    } finally {
      setLoadingVCard(false);
    }
  };

  return (
    <>
      <Drawer
        onClose={() => close()}
        open={isOpen}
        anchor="right"
        variant="temporary"
        classes={{ paper: styles['drawer-paper'] }}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
              Публичный профиль
            </Typography>
            <div>
              <IconButton
                title="Экспортировать vCard"
                onClick={() => void handleVCardDownload()}
                disabled={loadingVCard}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={close} className={styles['close-button']}>
                <CloseIcon title="Закрыть окно" />
              </IconButton>
            </div>
          </div>
          <div className={styles.content}>
            {publicProfile.picture ? (
              <div
                style={{
                  backgroundImage: `url(${getImageURL(publicProfile.picture)})`,
                }}
                className={styles['user-icon-wrapper']}
              />
            ) : null}
            <Typography className={clsx('text-14', 'color-3C4567', styles.email)}>
              {publicProfile.email}
            </Typography>

            {linesInfo.length > 0 && (
              <div className={styles.panel}>
                <Typography className={clsx('header-3', 'font-golos', styles['panel-title'])}>
                  Основная информация
                </Typography>
                {linesInfo}
              </div>
            )}

            {linesExternalAccounts.length > 0 && (
              <div className={styles.panel}>
                <Typography className={clsx('header-3', 'font-golos', styles['panel-title'])}>
                  Внешние аккаунты
                </Typography>
                {linesExternalAccounts}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
