import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter, Navigate, Route, Routes } from 'react-router-dom-v5-compat';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './components/custom/Theme';
import { Code } from './components/Code';
import Box from '@mui/material/Box';
import styles from './App.module.css';
import { PrivateRoute } from './components/routes/PrivateRoute';
import { Login } from './components/Login';
import { convertUserProfile, isOwner, once } from './helpers';
import { getUserRoleInApp } from './requests/user';
import { CLIENT_ID } from './constants';
import { setIsUserProfileLoading, setUserProfile } from './redux/userSlice';
import { RootState } from './redux/store';
import { TAppSlice, setIsMobile } from './redux/appSlice';
import { TNotice, setNotice } from './redux/noticesSlice';
import { Applications } from './components/applications/Applications';
import { ApplicationInfo } from './components/applications/ApplicationInfo';
import { CreateApplication } from './components/applications/CreateApplication';
import { EditProfile } from './components/profile/EditProfile';
import { UserProfile } from './components/profile/UserProfile';
import { DeleteProfile } from './components/profile/DeleteProfile';
import { ChangePassword } from './components/profile/ChangePassword';
import { ConfirmEmail } from './components/profile/ConfirmEmail';
import { BindKloudAccount } from './components/profile/BindKloudAccount';
import { NotificationPanel } from './components/NotificationPanel';
import { ProviderProfile } from './components/profile/ProviderProfile';
import { FillProfile } from './components/profile/FillProfile';
import { ApplicationUserInfo } from './components/applications/ApplicationUserInfo';
import { EventLog } from './components/EventLog';
import { BindLDAPAccount } from './components/profile/BindLDAPAccount';
import { Roles } from './enums';
import { RestoreProfile } from './components/profile/RestoreProfile';
import { ProviderUserProfile } from './components/profile/ProviderUserProfile';
import { ConfirmUserEmail } from './components/profile/ConfirmUserEmail';
import { OwnerOrEditorRoute } from './components/routes/OwnerOrEditorRoute';
import { OwnerRoute } from './components/routes/OwnerRoute';
import { DeleteProfileAsAdmin } from './components/profile/DeleteProfileAsAdmin';
import { ESnackBarVariant } from './components/custom/CustomSnackbar';
import { UsersControl } from './components/users/UsersControl';
import { Error } from './components/Error';
import { EditApplicationWrapper } from './components/applications/EditApplicationWrapper';
import { AddPhoneBySms } from './components/profile/AddPhoneBySms';
import { Bind1CAccount } from './components/profile/Bind1CAccount';
import { ApplicationsTable } from './components/applications/ApplicationsTable';
import { ApplicationRightPanel } from './components/applications/ApplicationRightPanel';
import { TopBarWrapper } from './components/TopBarWrapper';
import { UserEventLog } from './components/profile/UserEventLog';
import UserEvent from './components/profile/UserEvent';
import { AdminRoute } from './components/routes/AdminRoute';
import { ChangePasswordByOwner } from './components/applications/ChangeUserPasswordByOwner';
import { CreateUser } from './components/users/CreateUser';
import { PublicRoute } from './components/routes/PublicRoute';
import { useGetLicensesQuery } from './redux/services/owner';
import { BindIdmAccount } from './components/profile/BindIdmAccount';
import { AddPhone } from './components/profile/AddPhone';
import { useGetUserQuery } from './redux/services/user';
import { EditProfileAsOwner } from './components/profile/EditProfileAsOwner';
import { getAccessToken } from './service/auth';
import { checkAccessToken } from './requests/oidc';

type TAppProps = {
  isMenuOpen: boolean;
  isNotificationPanelOpen: TAppSlice['isNotificationPanelOpen'];
  isSnackbarOpen: TAppSlice['isSnackbarOpen'];
  notices?: TNotice[];
  role?: Roles;
};

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.app.isMenuOpen,
  isNotificationPanelOpen: state.app.isNotificationPanelOpen,
  isSnackbarOpen: state.app.isSnackbarOpen,
  notices: state.notices.notices,
  role: state.user.userProfile.role,
  isModalOpen: state.app.isModalOpen,
});

const AppComponent: React.FC<TAppProps> = ({
  isNotificationPanelOpen,
  isSnackbarOpen,
  notices,
  role,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isAccessToken, setIsAccessToken] = useState(false);
  const { data: user, isLoading } = useGetUserQuery(undefined, {
    skip: !isAccessToken,
  });

  // #807
  // const { data: availableUsersCount } = useGetAvailableUsersCountQuery(undefined, {
  //   skip: !isOwner(role),
  // });
  const {
    data: licenses,
    isFetching: licensesFetching,
    isUninitialized,
  } = useGetLicensesQuery(undefined, {
    skip: !isOwner(role),
  });

  const noticeOnce = useCallback(
    once(() => {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message: 'У вас нет активных лицензий.',
          timestamp: new Date().toString(),
        }),
      );
    }),
    [],
  );

  useLayoutEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          const checkResult = await checkAccessToken(token);
          setIsAccessToken(checkResult);
        } else {
          setIsAccessToken(false);
        }
      } catch (e) {
        console.log('getToken error: ' + e);
      }
    };
    getToken();
    return () => {
      setIsAccessToken(false);
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      dispatch(setIsMobile(window.innerWidth < 1024));
    };
    onResize();
    window.addEventListener('resize', onResize);
  }, []);

  // #807
  // useEffect(() => {
  //   if (availableUsersCount !== undefined && availableUsersCount < 100) {
  //     dispatch(
  //       setNotice({
  //         error: 'Ошибка',
  //         id: Math.random(),
  //         isRead: false,
  //         message:
  //           availableUsersCount <= 0
  //             ? 'Места для регистрации пользователей закончились.'
  //             : `Заканчиваются места для регистрации пользователей (осталось: ${availableUsersCount}).`,
  //         timestamp: new Date().toString(),
  //       }),
  //     );
  //   }
  // }, [availableUsersCount]);

  useEffect(() => {
    if (!licensesFetching && !isUninitialized && !licenses?.length) {
      noticeOnce();
    }
  }, [licensesFetching]);

  useEffect(() => {
    if (isLoading) dispatch(setIsUserProfileLoading(true));
  }, [isLoading]);

  useEffect(() => {
    const getProfile = async () => {
      if (user) {
        const convertedProfile = convertUserProfile(user);
        const role = await getUserRoleInApp(convertedProfile.id || '', CLIENT_ID);
        dispatch(setUserProfile({ ...convertedProfile, role }));
      }
      dispatch(setIsUserProfileLoading(false));
    };

    getProfile();
  }, [user]);

  useEffect(() => {
    if (!isNotificationPanelOpen && !isSnackbarOpen) {
      const firstItem = notices?.[0];
      if (typeof firstItem?.message === 'string')
        enqueueSnackbar(firstItem?.message, {
          variant: 'customSnackbar',
          avatar: firstItem?.avatar,
          snackbarVariant: firstItem.error ? ESnackBarVariant.error : ESnackBarVariant.notification,
        });
    }
  }, [notices?.length]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <BrowserRouter>
          <CompatRouter>
            <Box className={styles.wrapper}>
              <TopBarWrapper />
              <Box
                onScroll={() => {
                  const activeElement = document.activeElement as HTMLElement;
                  const form = activeElement.closest('form');
                  if (form) {
                    activeElement?.blur();
                    activeElement?.focus({ preventScroll: true });
                  }
                }}
                className={styles.content}
              >
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/code" element={<Code />} />
                  <Route element={<PublicRoute />}>
                    <Route index path="/error/:text" element={<Error />} />
                    <Route index path="fill-profile" element={<FillProfile />} />
                  </Route>
                  <Route path="profile" element={<PrivateRoute />}>
                    <Route path="/profile" element={<UserProfile />} />
                    <Route path="/profile/edit" element={<EditProfile />} />
                    <Route path="/profile/delete" element={<DeleteProfile />} />
                    <Route path="/profile/change-password" element={<ChangePassword />} />
                    <Route path="/profile/email/:action" element={<ConfirmEmail />} />
                    <Route path="/profile/restore-profile" element={<RestoreProfile />} />
                    <Route
                      path="/profile/scopes"
                      element={
                        <div className={styles['tab-container']}>
                          <ApplicationsTable variant="scopes" />
                          <ApplicationRightPanel variant="scopes" />
                        </div>
                      }
                    />
                    <Route
                      path="/profile/scopes/:clientId"
                      element={
                        <div className={styles['tab-container']}>
                          <ApplicationInfo />
                        </div>
                      }
                    />
                    <Route path="/profile/sms/add/:provider_id" element={<AddPhoneBySms />} />
                    <Route path="/profile/kloud/add/:provider_id" element={<BindKloudAccount />} />
                    <Route path="/profile/phone/:action" element={<AddPhone />} />
                    <Route path="/profile/ldap/add/:provider_id" element={<BindLDAPAccount />} />
                    <Route path="/profile/idm/add/:provider_id" element={<BindIdmAccount />} />
                    <Route path="/profile/1c/add/:provider_id" element={<Bind1CAccount />} />
                    <Route path="/profile/external-provider" element={<ProviderProfile />} />
                    <Route path="/profile/activity" element={<UserEventLog />} />
                    <Route path="/profile/activity/:eventId" element={<UserEvent />} />
                  </Route>

                  <Route path="applications" element={<AdminRoute />}>
                    <Route path="/applications" element={<Applications />} />
                    <Route path="/applications/create" element={<CreateApplication />} />

                    <Route path="/applications/event-log" element={<EventLog />} />
                  </Route>

                  <Route path="applications" element={<OwnerOrEditorRoute />}>
                    <Route path="/applications/:clientId" element={<ApplicationInfo />} />
                    <Route
                      path="/applications/edit/:clientId/:openedFromMenu?"
                      element={<EditApplicationWrapper />}
                    />
                    <Route
                      path="/applications/user/:clientId/:userId"
                      element={<ApplicationUserInfo />}
                    />
                  </Route>

                  <Route path="applications" element={<OwnerRoute />}>
                    <Route path="/applications/users" element={<UsersControl />} />
                    <Route path="/applications/users/create" element={<CreateUser />} />
                    <Route
                      path="/applications/user/edit/:clientId/:userId"
                      element={<EditProfileAsOwner />}
                    />
                    <Route
                      path="/applications/user/delete/:clientId/:userId"
                      element={<DeleteProfileAsAdmin />}
                    />
                    <Route
                      path="/applications/user/external-provider/:clientId/:userId"
                      element={<ProviderUserProfile />}
                    />
                    <Route
                      path="/applications/user/email/:action/:clientId/:userId"
                      element={<ConfirmUserEmail />}
                    />
                    <Route
                      path="/applications/user/:clientId/:userId/change-password"
                      element={<ChangePasswordByOwner />}
                    />
                  </Route>

                  <Route path="*" element={<Navigate to="/profile" replace />} />
                  {/* {NODE_ENV === 'development' && <Route path="/gallery" children={<Gallery />} />} */}
                </Routes>
              </Box>
              <NotificationPanel />
            </Box>
          </CompatRouter>
        </BrowserRouter>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export const App = connect(mapStateToProps)(AppComponent);
