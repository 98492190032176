import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ConfirmEmail.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { isApiErrorWithMessage } from '../../helpers';
import {
  useAddEmailMutation,
  useChangeEmailMutation,
  useGetExternalAccountsQuery,
} from '../../redux/services/user';
import {
  MailCodeTypes,
  useLazyCheckEmailAvialabilityQuery,
  useSendEmailCodeMutation,
} from '../../redux/services/mail';
import { useParams, useNavigate } from 'react-router-dom-v5-compat';

const mapStateToProps = (state: RootState) => ({
  currentEmail: state.user.userProfile.email,
  userId: state.user.userProfile.id,
  nickname: state.user.userProfile.nickname,
});

type TConfirmEmailComponent = {
  currentEmail?: TUserProfile['email'];
  userId?: string;
  nickname?: string;
};

export const ConfirmEmailComponent: FC<TConfirmEmailComponent> = ({
  currentEmail,
  userId,
  nickname,
}) => {
  const [changeEmail] = useChangeEmailMutation();
  const [addEmail] = useAddEmailMutation();
  const [checkEmailAvialability] = useLazyCheckEmailAvialabilityQuery();
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userId), {
    skip: !userId,
  });
  const currentEmailId = externalAccounts?.find((account) => account.email === currentEmail)?.id;
  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState('');
  const navigate = useNavigate();
  const [isMailSent, setIsMailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [[minute, second], setTime] = useState([1, 30]);
  const [code, setCode] = useState('');
  const [externalAccountExists, setExternalAccountExists] = useState(false);
  const { action } = useParams<{ action: string }>();
  const [sendEmailCode, { isLoading: sendEmailCodeLoading }] = useSendEmailCodeMutation();

  useEffect(() => {
    if (action === 'change' && currentEmail) setEmail(currentEmail);
    else setEmail('');
  }, [currentEmail, action]);

  useEffect(() => {
    setTime([1, 30]);
  }, [isMailSent]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const getEmailError = () => {
    const emailTest =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!email) return 'Укажите адрес электронной почты';
    if (email.length > 255) return 'Адрес электронной почты не может превышать 255 символов';
    if (externalAccounts?.find((account) => account.email === email))
      return 'Почта уже привязана к вашему аккаунту';
    if (!emailTest.test(email)) {
      return 'Неверный формат адреса электронной почты';
    }
  };

  const getCodeError = () => {
    if (!code) return 'Укажите код подтверждения';
  };

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const handleEmailAction = async () => {
    if (getCodeError()) return;
    try {
      if (email && userId) {
        const result =
          action === 'change'
            ? await changeEmail({
                email,
                userId,
                code,
                type: MailCodeTypes.changeMail,
                rebind: externalAccountExists,
                currentEmailId: externalAccountExists ? currentEmailId : null,
              })
            : await addEmail({
                newEmail: email,
                userId,
                code,
                rebind: externalAccountExists,
              });

        if ('error' in result) {
          const { error } = result;
          if ('data' in error && isApiErrorWithMessage(error.data)) {
            return setCodeError(error.data.message);
          }
          return setCodeError('Неверный код подтверждения');
        }

        navigate('/profile');
      }
    } catch (e) {
      console.log('checkEmailCodeError', e);
    }
  };

  const emailInput = () => {
    setIsMailSent(!isMailSent);
    setCodeError('');
    setCode('');
  };

  const sendConfirmationCode = async (resend: boolean) => {
    try {
      if (getEmailError()) return;

      if (userId && email) {
        const response = await checkEmailAvialability(email).unwrap();

        if (!externalAccountExists && !response.isAvailable) {
          setExternalAccountExists(true);
          return setEmailError(`Почтовый адрес уже используется ${response.nickname}`);
        }

        await sendEmailCode({
          type: action === 'change' ? MailCodeTypes.changeMail : MailCodeTypes.addEmail,
          userId: userId,
          email: email,
          name: nickname,
          resend,
          rebind: externalAccountExists,
        })
          .unwrap()
          .catch((e) => {
            if (e?.data?.message === 'code.still.active') {
              return;
            }
            throw e;
          });
        setIsMailSent(true);

        setTime([1, 30]);
      }
    } catch (e) {
      console.log('sendConfirmationCodeError ', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          {action === 'change' ? 'Изменить почту' : 'Добавить почту'}
        </Typography>
        {!isMailSent ? (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              {action === 'change'
                ? 'Укажите новый адрес электронной почты'
                : 'Укажите дополнительный адрес электронной почты'}
            </Typography>
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (externalAccountExists) setExternalAccountExists(false);
                if (emailError) setEmailError('');
              }}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const emailErr = getEmailError();
                if (emailErr) setEmailError(emailErr);
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!emailError}
              helperText={emailError && !externalAccountExists ? emailError : ''}
              fullWidth
              variant="standard"
            />
            {externalAccountExists && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ color: '#d32f2f' }} className={'text-14'}>
                  {emailError}
                </Typography>
                <Button
                  onClick={() => sendConfirmationCode(false)}
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                >
                  Перепривязать
                </Button>
              </div>
            )}
            <Typography className={clsx('text-14', 'color-858BA0')}>
              На этот адрес будет отправлен код подтверждения
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Введите код, отправленный на указанный адрес
            </Typography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <Typography className={clsx('text-14', 'color-858BA0')}>
                  Выслать код повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </Typography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={() => sendConfirmationCode(true)}
                >
                  Выслать код повторно
                </Button>
              )}
              <Button
                onClick={emailInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить почту
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            disabled={sendEmailCodeLoading || (externalAccountExists && !isMailSent)}
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isMailSent) {
                return handleEmailAction();
              }
              sendConfirmationCode(false);
            }}
          >
            {isMailSent ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ConfirmEmail = connect(mapStateToProps)(ConfirmEmailComponent);
