import { TWidgetColors } from './EditApplication';
import * as yup from 'yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import React, { FC, useEffect, useState, useRef, KeyboardEvent, ClipboardEvent } from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { SubmitHandler, useForm, FormProvider, useWatch, Controller } from 'react-hook-form';
import { TClient, useUpdateApplicationMutation, clientApi } from '../../redux/services/client';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationFormChanged } from '../../redux/appSlice';
import { CustomRadioButton } from '../CustomRadioButton';
import { AddProvider } from '../AddProvider';
import { TOauthProvider, TMiscProvider, providerApi } from '../../redux/services/provider';
import { getClaimPrivacy, isObjectEmpty } from '../../helpers';
import { EditApplicationHeader } from './EditApplicationHeader';
import { EditApplicationFooter } from './EditApplicationFooter';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from '../../redux/store';
import { CUSTOM_USER_FIELDS, PROJECT_NAME } from '../../constants';
import { useGetLicensesQuery } from '../../redux/services/owner';
import { setNotice } from '../../redux/noticesSlice';
import { DefaultPublicStatusPopover } from './DefaultPublicStatusPopover';
import { LicenseModal } from './LicenseModal';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { EGrantTypes } from './CreateApplication';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {
  settingsApi,
  useEditSettingsMutation,
  useGetSettingsQuery,
} from '../../redux/services/settings';

export enum RegistrationPolicyVariants {
  allowed = 'allowed',
  allowed_autoregistration_only = 'allowed_autoregistration_only',
  disabled = 'disabled',
}

export type TEditSettingsInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  avatar: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  widget_title: string;
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  allowed_symbols: string;
  ldap_password_error: string;
  allowed_special_symbols: string;
  min_uppercase_count: number;
  number: number;
  spec_char: number;
  length_char_min: number;
  length_char_max: number;
  max_age: number;
  min_age: number;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
};

const schema = yup
  .object({
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    registration_policy: yup.string().required('Обязательно поле'),
    allow_everyone_to_create_applications: yup.boolean().required('Обязательно поле'),
    authorize_only_admins: yup.boolean().required('Обязательно поле'),
    allowed_symbols: yup.string().required('Обязательное поле'),
    ldap_password_error: yup.string().required('Обязательное поле'),
    allowed_special_symbols: yup.string().required('Обязательное поле'),
    min_uppercase_count: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    number: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    spec_char: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    length_char_min: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    length_char_max: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    max_age: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
    min_age: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(0, 'Значение не может быть отрицательным')
      .integer('Значение должно быть целым числом')
      .required('Обязательное поле'),
  })
  .required();

type TEditSettings = {
  selectedClient: TClient;
  userId: string;
};

export const EditSettings: FC<TEditSettings> = ({ selectedClient, userId }) => {
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<
    TOauthProvider | TMiscProvider | undefined
  >(undefined);
  const [claims, setClaims] = useState<{ gravatar: string[]; oauth: string[] }>({
    gravatar: [],
    oauth: [],
  });
  // #807
  // const { data: availableUsersCount } = useGetAvailableUsersCountQuery();

  const savedCallback = useRef<() => void>();

  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateApplication, updateApplicationResult] = useUpdateApplicationMutation();
  const { data: licenses, isFetching: licensesFetching } = useGetLicensesQuery();
  const [editSettings, editSettingsResult] = useEditSettingsMutation();
  const { openedFromMenu } = useParams<{ openedFromMenu: string }>();
  const { data: dataSettings } = useGetSettingsQuery();
  const hasActiveLicense = !licensesFetching && licenses?.length;

  useEffect(() => {
    if (dataSettings)
      setClaims({
        oauth: dataSettings.default_public_profile_claims_oauth
          ? dataSettings.default_public_profile_claims_oauth.split(' ')
          : [],
        gravatar: dataSettings.default_public_profile_claims_gravatar
          ? dataSettings.default_public_profile_claims_gravatar.split(' ')
          : [],
      });
  }, [dataSettings]);

  useEffect(() => {
    setValue('default_public_profile_claims_oauth', claims.oauth.join(' '), { shouldDirty: true });
    setValue('default_public_profile_claims_gravatar', claims.gravatar.join(' '), {
      shouldDirty: true,
    });
  }, [claims]);

  useEffect(() => {
    if (updateApplicationResult.isSuccess && editSettingsResult.isSuccess) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Настройки личного кабинета сохранены.`,
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [updateApplicationResult, editSettingsResult]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
      setClaims({
        gravatar: [],
        oauth: [],
      });
    };
  }, []);

  const methods = useForm<TEditSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient?.redirect_uris.map((uri) => ({ value: uri })) || [
        { value: '' },
      ],
      post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris.map((uri) => ({
        value: uri,
      })) || [{ value: '' }],
      avatar: selectedClient?.avatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
  } = methods;

  const registration_policy = useWatch({ control, name: 'registration_policy' });
  const allow_everyone_to_create_applications = useWatch({
    control,
    name: 'allow_everyone_to_create_applications',
  });
  const authorize_only_admins = useWatch({ control, name: 'authorize_only_admins' });

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TEditSettingsInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;
    const payload = Object.entries(data)
      .filter(([key]) => {
        if (key === 'phone_issuer' || key === 'phone_client_id' || key === 'phone_client_secret')
          return true;
        return Object.keys(dirtyFields).includes(key);
      })
      .map(([key, value]) => {
        if (key === 'post_logout_redirect_uris' || key === 'redirect_uris') {
          return [
            key,
            data[key].reduce((dataAcc: string[], uri) => {
              if (uri.value) dataAcc.push(uri.value);
              return dataAcc;
            }, []),
          ];
        }

        return [key, value];
      });

    const isKeyOfGeneralSettings = (
      key:
        | string
        | number
        | boolean
        | string[]
        | File
        | {
            name: string;
            value: string;
          }[]
        | TWidgetColors
        | null,
    ) => {
      return (
        typeof key === 'string' &&
        [
          'min_uppercase_count',
          'number',
          'spec_char',
          'length_char_min',
          'length_char_max',
          'max_age',
          'min_age',
          'registration_policy',
          'allow_everyone_to_create_applications',
          'authorize_only_admins',
          'ldap_password_error',
          'allowed_symbols',
          'allowed_special_symbols',
          'default_public_profile_claims_oauth',
          'default_public_profile_claims_gravatar',
        ].includes(key)
      );
    };

    await Promise.all([
      updateApplication({
        currentClientId: selectedClient?.client_id,
        params: {
          ...Object.fromEntries(payload.filter(([key]) => !isKeyOfGeneralSettings(key))),
          grant_types: [
            EGrantTypes.authorization_code,
            EGrantTypes.refresh_token,
            EGrantTypes.device_flow,
          ],
          registration_access_token: selectedClient?.registration_access_token?.jti,
          client_id: selectedClient?.client_id,
          redirect_uris: selectedClient?.redirect_uris,
          post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris,
        },
      }),
      editSettings(Object.fromEntries(payload.filter(([key]) => isKeyOfGeneralSettings(key)))),
    ]);

    dispatch(providerApi.util.invalidateTags(['Providers']));

    dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const handleNumericKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  };
  const handleNumericPaste = (e: ClipboardEvent) => {
    if (!/^\d+$/.test(e.clipboardData.getData('text/plain'))) e.preventDefault();
  };

  return (
    <>
      <div className={styles.wrapper}>
        {!openedFromMenu && (
          <>
            <Button
              onClick={() => {
                if (applicationFormChanged) {
                  savedCallback.current = () => navigate(-1);
                  return setSaveModalOpen(true);
                }
                navigate(-1);
              }}
              variant="custom3"
              className={clsx('text-15', styles['button-back'])}
              startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
            >
              Приложение
            </Button>
          </>
        )}
        {!!openedFromMenu && (
          <div className={styles['header-text']}>
            <Typography
              className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
            >
              Настройки личного кабинета
            </Typography>
            <Typography style={{ marginBottom: 14 }} className={clsx('text-14', 'color-858BA0')}>
              Добавляйте способы входа, управляйте доступом к личному кабинету и настраивайте
              внешний вид виджета для авторизации пользователей.
            </Typography>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditApplicationHeader
              coverSrc={coverSrc}
              setCoverSrc={setCoverSrc}
              avatarSrc={avatarSrc}
              client_id={selectedClient.client_id}
              setAvatarSrc={setAvatarSrc}
              setProviderModalOpen={setProviderModalOpen}
              setSelectedProvider={setSelectedProvider}
              openedFromMenu={openedFromMenu}
            />
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Лицензия
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ alignItems: 'flex-start' }}
                className={styles['accordion-details']}
              >
                <Button
                  variant="custom2"
                  className={styles['add-button']}
                  onClick={() => setLicenseModalOpen(true)}
                >
                  {!hasActiveLicense ? 'Активировать лицензию' : 'Лицензия активна'}
                </Button>
              </AccordionDetails>
            </Accordion>
            <LicenseModal close={() => setLicenseModalOpen(false)} isOpen={licenseModalOpen} />
            {/* <Button
              variant="custom2"
              className={styles['add-button']}
              onClick={() => setLicenseModalOpen(true)}
              startIcon={<AddIcon className={styles['add-icon']} />}
            >
              Добавить лицензию
            </Button> */}
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Настройки доступа
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ alignItems: 'flex-start' }}
                className={styles['accordion-details']}
              >
                <div className={styles['input-wrapper']}>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Ограниченный доступ
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            Вход в личный кабинет доступен только для пользователей с правами
                            “Администратор” {PROJECT_NAME}
                          </Typography>
                        </div>
                      }
                      checked={authorize_only_admins}
                      onClick={() => {
                        setValue('authorize_only_admins', true, { shouldDirty: true });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Неограниченный доступ
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            Вход в личный кабинет не ограничен
                          </Typography>
                        </div>
                      }
                      checked={!authorize_only_admins}
                      onClick={() => {
                        setValue('authorize_only_admins', false, { shouldDirty: true });
                      }}
                    />
                  </div>
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Запрет регистрации
                  </Typography>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация запрещена
                          </Typography>
                        </div>
                      }
                      checked={registration_policy === RegistrationPolicyVariants.disabled}
                      onClick={() => {
                        setValue('registration_policy', RegistrationPolicyVariants.disabled, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация разрешена
                          </Typography>
                        </div>
                      }
                      checked={registration_policy === RegistrationPolicyVariants.allowed}
                      onClick={() => {
                        setValue('registration_policy', RegistrationPolicyVariants.allowed, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Разрешена только авторегистрация
                          </Typography>
                        </div>
                      }
                      checked={
                        registration_policy ===
                        RegistrationPolicyVariants.allowed_autoregistration_only
                      }
                      onClick={() => {
                        setValue(
                          'registration_policy',
                          RegistrationPolicyVariants.allowed_autoregistration_only,
                          { shouldDirty: true },
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Регистрация пользователей с правами “Администратора”
                  </Typography>
                  <div className={styles['radio-wrapper']}>
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация с правами администратора
                          </Typography>
                        </div>
                      }
                      checked={allow_everyone_to_create_applications}
                      onClick={() => {
                        setValue('allow_everyone_to_create_applications', true, {
                          shouldDirty: true,
                        });
                      }}
                    />
                    <CustomRadioButton
                      className={styles.radio}
                      label={
                        <div>
                          <Typography className={clsx('text-14', 'color-0B1641')}>
                            Регистрация без прав администратора
                          </Typography>
                        </div>
                      }
                      checked={!allow_everyone_to_create_applications}
                      onClick={() => {
                        setValue('allow_everyone_to_create_applications', false, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Парольные политики
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                {
                  //PRIVATE REPO
                }
                {licenses?.length && (
                  <div className={styles['input-wrapper']}>
                    <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                      Описание ошибки несоответствия пароля парольным политикам AD
                    </Typography>
                    <TextField
                      {...register('ldap_password_error', {
                        onChange: () => {
                          if (errors.ldap_password_error) {
                            clearErrors('ldap_password_error');
                          }
                        },
                      })}
                      className="custom"
                      error={!!errors.ldap_password_error}
                      helperText={
                        errors.ldap_password_error ? errors.ldap_password_error.message : ''
                      }
                      fullWidth
                      variant="standard"
                    />
                  </div>
                )}
                {
                  //PRIVATE REPO END
                }
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Разрешенные символы
                  </Typography>
                  <TextField
                    {...register('allowed_symbols', {
                      onChange: () => {
                        if (errors.allowed_symbols) {
                          clearErrors('allowed_symbols');
                        }
                      },
                    })}
                    className="custom"
                    error={!!errors.allowed_symbols}
                    helperText={errors.allowed_symbols ? errors.allowed_symbols.message : ''}
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Разрешенные спецсимволы
                  </Typography>
                  <TextField
                    {...register('allowed_special_symbols', {
                      onChange: () => {
                        if (errors.allowed_special_symbols) {
                          clearErrors('allowed_special_symbols');
                        }
                      },
                    })}
                    className="custom"
                    error={!!errors.allowed_special_symbols}
                    helperText={
                      errors.allowed_special_symbols ? errors.allowed_special_symbols.message : ''
                    }
                    fullWidth
                    variant="standard"
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Минимальное количество символов в верхнем регистре
                  </Typography>
                  <Controller
                    name="min_uppercase_count"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.min_uppercase_count}
                        helperText={
                          errors.min_uppercase_count ? errors.min_uppercase_count.message : ''
                        }
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Минимальное количество цифр
                  </Typography>
                  <Controller
                    name="number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.number}
                        helperText={errors.number ? errors.number.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Минимальное количество спецсимволов
                  </Typography>
                  <Controller
                    name="spec_char"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.spec_char}
                        helperText={errors.spec_char ? errors.spec_char.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Минимальная длина пароля
                  </Typography>
                  <Controller
                    name="length_char_min"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.length_char_min}
                        helperText={errors.length_char_min ? errors.length_char_min.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Максимальная длина пароля
                  </Typography>
                  <Controller
                    name="length_char_max"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.length_char_max}
                        helperText={errors.length_char_max ? errors.length_char_max.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Настройки формы регистрации
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Максимальный возраст
                  </Typography>
                  <Controller
                    name="max_age"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.max_age}
                        helperText={errors.max_age ? errors.max_age.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
                <div className={styles['input-wrapper']}>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
                    Минимальный возраст
                  </Typography>
                  <Controller
                    name="min_age"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        className="custom"
                        error={!!errors.min_age}
                        helperText={errors.min_age ? errors.min_age.message : ''}
                        fullWidth
                        variant="standard"
                        InputProps={{ inputProps: { min: '0' } }}
                        onKeyDown={handleNumericKeyDown}
                        onPaste={handleNumericPaste}
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion className={clsx(styles.panel, styles.accordion)}>
              <AccordionSummary
                className={styles['accorion-summary']}
                classes={{ content: styles['accorion-summary-content'] }}
                expandIcon={<ArrowDownIcon fill="#0B1641" />}
              >
                <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                  Публичные поля профиля пользователя
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={styles['accordion-details']}>
                <div className={styles['info-item']}>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                  >
                    Публичное имя
                  </Typography>
                  <DefaultPublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'nickname',
                      claims.oauth.join(' '),
                      claims.gravatar.join(' '),
                    )}
                    claim="nickname"
                    claims={claims}
                    setClaims={setClaims}
                  />
                </div>
                <div className={styles['info-item']}>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                  >
                    Фото профиля
                  </Typography>
                  <DefaultPublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'picture',
                      claims.oauth.join(' '),
                      claims.gravatar.join(' '),
                    )}
                    claim="picture"
                    claims={claims}
                    setClaims={setClaims}
                  />
                </div>
                <div className={styles['info-item']}>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                  >
                    Имя и фамилия
                  </Typography>
                  <DefaultPublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'family_name',
                      claims.oauth.join(' '),
                      claims.gravatar.join(' '),
                    )}
                    claim="family_name given_name"
                    claims={claims}
                    setClaims={setClaims}
                  />
                </div>
                <div className={styles['info-item']}>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                  >
                    Логин
                  </Typography>
                  <DefaultPublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'login',
                      claims.oauth.join(' '),
                      claims.gravatar.join(' '),
                    )}
                    claim="login"
                    claims={claims}
                    setClaims={setClaims}
                  />
                </div>
                <div className={styles['info-item']}>
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                  >
                    Дата рождения
                  </Typography>
                  <DefaultPublicStatusPopover
                    claimPrivacy={getClaimPrivacy(
                      'birthdate',
                      claims.oauth.join(' '),
                      claims.gravatar.join(' '),
                    )}
                    claim="birthdate"
                    claims={claims}
                    setClaims={setClaims}
                  />
                </div>
                {Object.keys(CUSTOM_USER_FIELDS).map((field) => (
                  <div key={2} className={styles['info-item']}>
                    <Typography
                      className={clsx('text-14', 'color-0B1641', styles['info-item-title'])}
                    >
                      {CUSTOM_USER_FIELDS[field].title}
                    </Typography>
                    <DefaultPublicStatusPopover
                      claimPrivacy={getClaimPrivacy(
                        field,
                        claims.oauth.join(' '),
                        claims.gravatar.join(' '),
                      )}
                      claim={field}
                      claims={claims}
                      setClaims={setClaims}
                    />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
            <EditApplicationFooter
              coverSrc={coverSrc}
              applicationFormChanged={applicationFormChanged}
              avatarSrc={avatarSrc}
              savedCallback={savedCallback}
              setSaveModalOpen={setSaveModalOpen}
            />
          </form>
        </FormProvider>
        <AddProvider
          close={() => setProviderModalOpen(false)}
          clearSelectedProvider={() => setSelectedProvider(undefined)}
          isOpen={providerModalOpen}
          selectedProvider={selectedProvider}
        />
        <Modal open={saveModalOpen} onClose={closeSaveModal}>
          <div className={styles['save-modal']}>
            <div style={{ display: 'flex' }}>
              <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
                Сохранение изменений
              </Typography>
              <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
              Изменения не сохранены. Продолжить без сохранения?
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="custom" color="secondary" onClick={closeSaveModal}>
                Отмена
              </Button>
              <Button
                onClick={() => {
                  savedCallback.current?.();
                  dispatch(setApplicationFormChanged(false));
                  setSaveModalOpen(false);
                }}
                variant="custom"
                style={{ marginLeft: 24 }}
              >
                Продолжить
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
