import React, { useState, FC, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ConfirmUserEmail.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useLazyGetUsersQuery } from '../../redux/services/client';
import { useLazyCheckEmailAvialabilityQuery } from '../../redux/services/mail';
import { useDispatch } from 'react-redux';
import {
  useAddEmailByOwnerMutation,
  useUpdateUserByOwnerMutation,
} from '../../redux/services/owner';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import { userApi } from '../../redux/services/user';

export const ConfirmUserEmail: FC = () => {
  const [addEmail, { isLoading: addEmailLoading }] = useAddEmailByOwnerMutation();
  const [updateUser, { isLoading: updateUserLoading }] = useUpdateUserByOwnerMutation();
  const [getUsers] = useLazyGetUsersQuery();
  const [checkEmailAvialability] = useLazyCheckEmailAvialabilityQuery();
  const [emailError, setEmailError] = useState<null | string>(null);
  const [email, setEmail] = useState<string | undefined>();
  const navigate = useNavigate();
  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const action = useLocation().pathname.split('/')[4];
  const dispatch = useDispatch();

  useEffect(() => {
    if (action === 'change') {
      const start = async () => {
        const { data: users } = await getUsers({
          client_id: clientId || '',
          number_of_skip: '0',
          sort_direction: 'asc',
          search_string: '',
          search_param_user_id: userId,
        });
        setEmail(users?.find((user) => user.user.id === Number(userId))?.user.email);
      };
      start();
    } else setEmail('');
  }, [clientId, userId, action]);

  const getEmailError = () => {
    const emailTest =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!email) return setEmailError('Укажите адрес электронной почты');
    if (email) {
      if (!emailTest.test(email)) {
        return setEmailError('Неверный формат адреса электронной почты');
      }
    }
  };

  const handleEmailAction = async () => {
    try {
      if (emailError || !email || !userId) return;
      const { data } = await checkEmailAvialability(email);
      if (data && !data.isAvailable) {
        return setEmailError('Почтовый адрес уже используется');
      }

      if (action === 'add')
        await addEmail({
          email,
          user_id: userId,
        }).unwrap();
      else
        await updateUser({
          email,
          user_id: userId,
        });

      dispatch(userApi.util.invalidateTags(['ExternalAccounts']));
      navigate(`/applications/user/${clientId}/${userId}`);
    } catch (e) {
      console.log('checkEmailCodeError', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate(`/applications/user/${clientId}/${userId}`)}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          {action === 'change' ? 'Изменить почту' : 'Добавить почту'}
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          {action === 'change'
            ? 'Укажите новый адрес электронной почты'
            : 'Укажите дополнительный адрес электронной почты'}
        </Typography>
        <TextField
          value={email}
          onChange={(e) => {
            setEmailError(null);
            setEmail(e.target.value);
          }}
          className={clsx('custom', styles.textfield)}
          onBlur={getEmailError}
          FormHelperTextProps={{
            className: clsx('text-14', 'color-858BA0'),
          }}
          error={!!emailError}
          helperText={emailError ? emailError : ''}
          fullWidth
          variant="standard"
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button
            onClick={() => navigate(`/applications/user/${clientId}/${userId}`)}
            variant="custom"
            color="secondary"
          >
            Отмена
          </Button>
          <Button
            disabled={addEmailLoading || updateUserLoading}
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={handleEmailAction}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
