import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React from 'react';
import { formatDate } from '../../helpers';
import Button from '@mui/material/Button';
import styles from './UserProfile.module.css';
import { useNavigate } from 'react-router-dom-v5-compat';

export const ChangePasswordBlock = ({
  passwordUpdateDate,
  navigateTo,
}: {
  passwordUpdateDate: Date;
  navigateTo: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.panel}
      style={{ display: 'flex', paddingBottom: 22, alignItems: 'center' }}
    >
      <div style={{ paddingTop: 22 }}>
        <Typography
          style={{ marginBottom: '4px' }}
          className={clsx('text-17-regular', 'font-golos')}
        >
          Пароль
        </Typography>
        <Typography className={clsx('text-12', 'color-858BA0')}>
          {formatDate(passwordUpdateDate)}
        </Typography>
      </div>

      <Button
        onClick={() => navigate(navigateTo)}
        variant="custom2"
        style={{ marginLeft: 'auto' }}
        className={styles['margin-right']}
      >
        Изменить
      </Button>
    </div>
  );
};
