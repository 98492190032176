import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './AddPhoneBySms.module.css';
import { Button, Typography, TextField } from '@mui/material';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { useAddPhoneMutation } from '../../redux/services/user';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { NumericFormatCustom } from '../NumericFormatCustom';
import { useCallPhoneMutation, useLazyCheckPhoneExistsQuery } from '../../redux/services/phone';

export const AddPhone = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isPhoneCalled, setIsPhoneCalled] = useState(false);
  const [[minute, second], setTime] = useState([1, 30]);
  const [addPhone] = useAddPhoneMutation();
  const navigate = useNavigate();
  const [callPhone] = useCallPhoneMutation();
  const [checkPhoneExists] = useLazyCheckPhoneExistsQuery();
  const { action = '' } = useParams<{ action: string }>();

  useEffect(() => {
    setTime([1, 30]);
  }, [isPhoneCalled]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    if (minute === 0 && second === 0) {
      setTime([0, 0]);
    } else if (second == 0) {
      setTime([minute - 1, 59]);
    } else {
      setTime([minute, second - 1]);
    }
  };

  const getPhoneNumberError = () => {
    const phoneNumberTest = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    if (!phoneNumber) return 'Укажите номер телефона';
    if (!phoneNumberTest.test(phoneNumber)) {
      return 'Неверный формат номера телефона';
    }
  };

  const getCodeError = () => {
    if (!code) return 'Укажите 4 цифры';
  };

  const codeInput = () => {
    setIsPhoneCalled((isPhoneCalled) => !isPhoneCalled);
    setCodeError('');
    setCode('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    if (phoneNumberError) setPhoneNumberError('');
  };

  const handleCallPhone = async () => {
    if (getPhoneNumberError()) return;
    try {
      const checkPhoneResponse = await checkPhoneExists(phoneNumber).unwrap();
      if (!checkPhoneResponse?.isAvailable) {
        return setPhoneNumberError('Номер телефона используется другим пользователем');
      }

      await callPhone(phoneNumber).unwrap();

      setIsPhoneCalled(true);
      setTime([1, 30]);
    } catch (e) {
      console.log('handleCallPhone ', e);
    }
  };

  const handleCheckPhoneCode = async () => {
    if (getCodeError()) return;
    try {
      //   const ok = await checkPhoneCode(phoneNumber, code);

      //   if (!ok) return setCodeError('Неправильный код');

      await addPhone({
        code,
        phone_number: '+7' + phoneNumber,
      }).unwrap();

      navigate('/profile');
    } catch (e) {
      console.log('handleCheckPhoneCode', e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => navigate('/profile')}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-email']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          {action === 'add' ? 'Добавить телефон' : 'Изменить номер телефона'}
        </Typography>
        {!isPhoneCalled ? (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Укажите номер телефона
            </Typography>
            <TextField
              value={phoneNumber}
              onChange={handleChange}
              className={clsx('custom', styles.textfield)}
              onBlur={() => {
                const phoneNumberErr = getPhoneNumberError();
                if (phoneNumberErr) setPhoneNumberError(phoneNumberErr);
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!phoneNumberError}
              helperText={phoneNumberError ? phoneNumberError : ''}
              fullWidth
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: NumericFormatCustom as any,
              }}
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0')}>
              На указаный номер поступит бесплатный звонок
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Введите 4 последние цифры входящего номера
            </Typography>
            <TextField
              className={clsx('custom', styles.textfield)}
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                if (codeError) setCodeError('');
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              onBlur={() => {
                const codeErr = getCodeError();
                if (codeErr) setCodeError(codeErr);
              }}
              error={!!codeError}
              helperText={codeError ? codeError : ''}
              fullWidth
              variant="standard"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {minute || second !== 0 ? (
                <Typography className={clsx('text-14', 'color-858BA0')}>
                  Позвонить повторно через
                  {` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`}
                </Typography>
              ) : (
                <Button
                  variant="custom2"
                  className={clsx('text-14')}
                  style={{ padding: 0 }}
                  onClick={handleCallPhone}
                >
                  Позвонить повторно
                </Button>
              )}
              <Button
                onClick={codeInput}
                variant="custom2"
                className={clsx('text-14')}
                style={{ padding: 0 }}
              >
                Изменить номер
              </Button>
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
          <Button onClick={() => navigate('/profile')} variant="custom" color="secondary">
            Отмена
          </Button>
          <Button
            variant="custom"
            style={{ marginLeft: 24 }}
            onClick={() => {
              if (isPhoneCalled) {
                return handleCheckPhoneCode();
              }
              handleCallPhone();
            }}
          >
            {isPhoneCalled ? 'Подтвердить' : 'Продолжить'}
          </Button>
        </div>
      </div>
    </div>
  );
};
