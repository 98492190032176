import React, { FC, useState } from 'react';
import styles from './ChooseProvider.module.css';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { ReactComponent as InfoIcon } from '../icons/Info.svg';
import {
  MiscProviderType,
  OauthProviderType,
  PAID_PROVIDERS,
  useCreateProviderMutation,
} from '../redux/services/provider';
import { CreateProvider } from './CreateProvider';
import { CreateProviderByTemplate } from './CreateProviderByTemplate';
import { BACKEND_URL } from '../constants';
import { CreateEthereumProvider } from './CreateEthereumProvider';
import { CreateSmsProvider } from './CreateSmsProvider';
import { useParams } from 'react-router-dom-v5-compat';
import { CreateKloudProvider } from './CreateKloudProvider';

//PRIVATE REPO
import { Create1CProvider } from './Create1CProvider';
import { CreateIdmProvider } from './CreateIdmProvider';
import { CreateLdapProvider } from './CreateLdapProvider';
import { useAppActivatedQuery } from '../redux/services/client';
//PRIVATE REPO END

type TCreateProvider = {
  isOpen: boolean;
  close: () => void;
};

export const ChooseProvider: FC<TCreateProvider> = ({ isOpen, close }) => {
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [createProvider] = useCreateProviderMutation();

  const ldapProviderAvatar = 'public/images/provider/ldap.svg';
  const aldProProviderAvatar = 'public/images/provider/ald-pro.png';
  const _1CProviderAvatar = 'public/images/provider/1c.svg';
  const ethereumProviderAvatar = 'public/images/provider/ethereum.svg';
  const smsProviderAvatar = 'public/images/provider/sms.svg';
  const idmProviderAvatar = 'public/images/provider/idm.svg';
  const kloudProviderAvatar = 'public/images/provider/kloud.svg';

  const [providerTemplate, setProviderTemplate] = useState<{
    type: OauthProviderType | MiscProviderType;
    name: string;
    pathToAvatar: string;
  }>({
    type: OauthProviderType.CUSTOM,
    name: '',
    pathToAvatar: '',
  });

  const { data: activated } = useAppActivatedQuery();
  const [isCreateFormTemplateOpen, setIsCreateFormTemplateOpen] = useState(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isCreateFormLdapOpen, setIsCreateFormLdapOpen] = useState(false);
  const [isCreateFormAldProOpen, setIsCreateFormAldProOpen] = useState(false);
  const [isCreateForm1COpen, setIsCreateForm1COpen] = useState(false);
  const [isCreateFormIdmOpen, setIsCreateFormIdmOpen] = useState(false);
  const [isCreateFormEthereumOpen, setIsCreateFormEthereumOpen] = useState(false);
  const [isCreateFormSmsOpen, setIsCreateFormSmsOpen] = useState(false);
  const [isCreateFormKloudOpen, setIsCreateFormKloudOpen] = useState(false);

  const providers: {
    type: OauthProviderType | MiscProviderType;
    name: string;
    pathToAvatar: string;
  }[] = [
    {
      type: OauthProviderType.YANDEX,
      name: 'Яндекс',
      pathToAvatar: 'public/images/provider/yandex.svg',
    },
    {
      type: OauthProviderType.VK,
      name: 'ВКонтакте',
      pathToAvatar: 'public/images/provider/vk.svg',
    },
    {
      type: OauthProviderType.MAILRU,
      name: 'Mail.ru',
      pathToAvatar: 'public/images/provider/mail.svg',
    },
    {
      type: OauthProviderType.GOOGLE,
      name: 'Google',
      pathToAvatar: 'public/images/provider/google.svg',
    },
    {
      type: MiscProviderType.KLOUD,
      name: 'Kloud.One',
      pathToAvatar: kloudProviderAvatar,
    },
    {
      type: OauthProviderType.CUSTOM,
      name: '',
      pathToAvatar: '',
    },
    //PRIVATE REPO
    {
      type: MiscProviderType.LDAP,
      name: 'LDAP',
      pathToAvatar: ldapProviderAvatar,
    },
    {
      type: MiscProviderType.ALDPRO,
      name: 'ALDPRO',
      pathToAvatar: aldProProviderAvatar,
    },
    {
      type: MiscProviderType._1C,
      name: '1C',
      pathToAvatar: _1CProviderAvatar,
    },
    {
      type: MiscProviderType.IDM,
      name: 'IDM',
      pathToAvatar: idmProviderAvatar,
    },
    {
      type: MiscProviderType.QRCODE,
      name: 'QR-код',
      pathToAvatar: 'public/images/provider/qrcode.svg',
    },
    //PRIVATE REPO END
    {
      type: MiscProviderType.ETHEREUM,
      name: 'Ethereum',
      pathToAvatar: ethereumProviderAvatar,
    },
    {
      type: MiscProviderType.SMS,
      name: 'СМС',
      pathToAvatar: smsProviderAvatar,
    },
  ];

  const handleClose = () => {
    close();
  };

  const handleProviderClose = (
    closeProvider: (arg: false) => void,
    closeChooseProvider?: boolean,
  ) => {
    closeProvider(false);
    if (closeChooseProvider) close();
  };
  const providerDisabled = (providerType: string) =>
    PAID_PROVIDERS.includes(providerType as MiscProviderType) && !activated;

  return (
    <>
      <Drawer
        classes={{ paper: styles['drawer-paper'] }}
        BackdropProps={{ className: styles.backdrop }}
        onClose={handleClose}
        open={isOpen}
        anchor="right"
        variant="temporary"
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа
            </Typography>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['choose-provider']}>
            <Typography
              className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
            >
              Выберите шаблон способа входа
            </Typography>
            {providers.map((provider, index) => {
              return (
                <ListItem
                  key={Date.now() + index}
                  className={clsx(styles.provider, {
                    [styles['provider-disabled']]: providerDisabled(provider.type),
                  })}
                  onClick={async () => {
                    try {
                      if (providerDisabled(provider.type)) return;
                      if (provider.type === OauthProviderType.CUSTOM) {
                        setIsCreateFormOpen(true);
                      } else if (provider.type === MiscProviderType.LDAP) {
                        setIsCreateFormLdapOpen(true);
                      } else if (provider.type === MiscProviderType.ALDPRO) {
                        setIsCreateFormAldProOpen(true);
                      } else if (provider.type === MiscProviderType.ETHEREUM) {
                        setIsCreateFormEthereumOpen(true);
                      } else if (provider.type === MiscProviderType.SMS) {
                        setIsCreateFormSmsOpen(true);
                      } else if (provider.type === MiscProviderType.KLOUD) {
                        setIsCreateFormKloudOpen(true);
                      } else if (provider.type === MiscProviderType._1C) {
                        setIsCreateForm1COpen(true);
                      } else if (provider.type === MiscProviderType.IDM) {
                        setIsCreateFormIdmOpen(true);
                      } else if (provider.type === MiscProviderType.QRCODE) {
                        await createProvider({
                          body: {
                            trusted_type: MiscProviderType.QRCODE,
                            name: 'QR-код',
                            type: MiscProviderType.QRCODE,
                            path_to_avatar: provider.pathToAvatar,
                          },
                          client_id: clientId,
                        }).unwrap();
                        close();
                      } else if (
                        provider.type !== MiscProviderType.CREDENTIALS &&
                        provider.type !== MiscProviderType.EMAIL
                      ) {
                        setProviderTemplate({
                          ...providerTemplate,
                          type: provider.type,
                          name: provider.name,
                          pathToAvatar: provider.pathToAvatar,
                        });
                        setIsCreateFormTemplateOpen(true);
                      }
                    } catch (e) {
                      console.log('createProvider error', e);
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.pathToAvatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.pathToAvatar && <IdIcon />}
                  </div>
                  <Typography className={clsx('text-14', 'color-0B1641', styles['provider-name'])}>
                    {provider.name || 'OpenID Connect'}
                  </Typography>
                  {providerDisabled(provider.type) && (
                    <Tooltip
                      classes={{
                        tooltip: styles['input-tooltip'],
                      }}
                      title={<div>Операция недоступна. Требуется лицензия.</div>}
                    >
                      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <InfoIcon className={styles['info-icon']} color="red" />
                      </div>
                    </Tooltip>
                  )}
                </ListItem>
              );
            })}
          </div>
        </div>
      </Drawer>
      <CreateProviderByTemplate
        isOpen={isCreateFormTemplateOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormTemplateOpen, closeChooseProvider);
        }}
        providerTemplate={providerTemplate}
      />
      <CreateProvider
        isOpen={isCreateFormOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormOpen, closeChooseProvider);
        }}
      />
      {
        //PRIVATE REPO
      }
      <CreateLdapProvider
        pathToAvatar={ldapProviderAvatar}
        isOpen={isCreateFormLdapOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormLdapOpen, closeChooseProvider);
        }}
        providerType={MiscProviderType.LDAP}
      />
      <CreateLdapProvider
        pathToAvatar={aldProProviderAvatar}
        isOpen={isCreateFormAldProOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormAldProOpen, closeChooseProvider);
        }}
        providerType={MiscProviderType.ALDPRO}
      />
      <Create1CProvider
        pathToAvatar={_1CProviderAvatar}
        isOpen={isCreateForm1COpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateForm1COpen, closeChooseProvider);
        }}
      />
      <CreateIdmProvider
        pathToAvatar={idmProviderAvatar}
        isOpen={isCreateFormIdmOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormIdmOpen, closeChooseProvider);
        }}
      />
      {
        //PRIVATE REPO END
      }
      <CreateEthereumProvider
        pathToAvatar={ethereumProviderAvatar}
        isOpen={isCreateFormEthereumOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormEthereumOpen, closeChooseProvider);
        }}
      />
      <CreateSmsProvider
        pathToAvatar={smsProviderAvatar}
        isOpen={isCreateFormSmsOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormSmsOpen, closeChooseProvider);
        }}
      />
      <CreateKloudProvider
        pathToAvatar={kloudProviderAvatar}
        isOpen={isCreateFormKloudOpen}
        close={(closeChooseProvider?: boolean) => {
          handleProviderClose(setIsCreateFormKloudOpen, closeChooseProvider);
        }}
      />
    </>
  );
};
