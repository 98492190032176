import React from 'react';
import Typography from '@mui/material/Typography';
// #427 import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styles from './EditProfile.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setUserProfile, TCustomFields, TUserProfile, TUserSlice } from '../../redux/userSlice';
import { updateUser, checkIdentifier } from '../../requests/user';
import { getUserProfile } from '../../requests/oidc';
import { convertUserProfile, getMonthsByYear, isDateError } from '../../helpers';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import { setIsModalOpen, setIsChangesUnsaved, setComponentPath } from '../../redux/appSlice';
import { CUSTOM_USER_FIELDS /* #427 PROJECT_NAME*/ } from '../../constants';
import { setNotice } from '../../redux/noticesSlice';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ProfileFields } from './ProfileFields';
import { useGetSettingsQuery } from '../../redux/services/settings';

export type EditProfileInputs = {
  given_name: string;
  picture: File | string | null;
  nickname: string;
  family_name: string;
  login: string;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

export const editProfileSchema = yup
  .object({
    nickname: yup
      .string()
      .max(71, 'Не может превышать 71 символ')
      .min(3, 'Не может быть меньше 3 символов')
      .matches(/^[^ ]+( *[^ ]+)*?$/, 'Не может содержать пробелы в начале и конце')
      .required('Обязательное поле'),
    given_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Имя может включать буквы (a-z) и (а-я), цифры (0-9) дефис (-), нижнее подчеркивание (_), апостроф ('), пробел и точку",
      ),
    family_name: yup
      .string()
      .max(50, 'Не может превышать 50 символов')
      .matches(
        /^([a-zA-Z0-9а-яА-ЯёЁ]+((\s|-|'|_|\.)[a-zA-Z0-9а-яА-ЯёЁ]+)*)?$/,
        "Фамилия может включать буквы (a-z) и (а-я), цифры (0-9) дефис (-), нижнее подчеркивание (_), апостроф ('), пробел и точку",
      ),
    login: yup
      .string()
      .min(3, 'Не может быть меньше 3 символов')
      .max(71, 'Не может превышать 71 символ')
      .matches(/^(?!\d+$).*$/, 'Логин не может состоять только из цифр')
      .matches(/^[^@]+$/, 'Логин не может содержать символ "@" ')
      .matches(/[^ ]+/, {
        message: 'Логин не может состоять только из пробелов',
      })
      // #584 .matches(/^[a-z0-9.]+$/, 'Может включать латинские буквы (a-z), цифры (0-9) и точку (.)')
      .required('Обязательное поле'),
  })
  .required();

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  userId: state.user.userProfile.id,
  isModalOpen: state.app.isModalOpen,
  isChangesUnsaved: state.app.isChangesUnsaved,
  componentPath: state.app.componentPath,
});

type TEditProfileComponent = {
  userProfile: TUserSlice['userProfile'];
  userId?: string;
  isModalOpen: boolean;
  isChangesUnsaved: boolean;
  componentPath: string;
};

const EditProfileComponent: FC<TEditProfileComponent> = ({
  userProfile,
  userId,
  isModalOpen,
  isChangesUnsaved,
  componentPath,
}) => {
  const methods = useForm<EditProfileInputs & TCustomFields>({
    resolver: yupResolver(editProfileSchema),
    defaultValues: {
      birthDay: '',
      birthMonth: '',
      birthYear: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
  } = methods;

  const watchBirthYear = watch('birthYear');
  // #427 const [isPublic, setIsPublic] = useState<boolean>(false);

  const navigate = useNavigate();

  const { data: dataSettings } = useGetSettingsQuery();

  const dispatch = useDispatch();
  const months = getMonthsByYear(watchBirthYear);

  useEffect(() => {
    const customFields = Object.keys(CUSTOM_USER_FIELDS).filter(
      (key) => userProfile[key as keyof TUserProfile],
    );

    (
      ['nickname', 'given_name', 'family_name', 'login', ...customFields] as Array<
        keyof Omit<EditProfileInputs, 'birthDay' | 'birthMonth' | 'birthYear' | 'picture'>
      >
    ).forEach((field) => {
      if (userProfile[field]) setValue(field, userProfile[field] || '');
    });
    if (userProfile.birthdate) {
      const date = new Date(userProfile.birthdate);
      setValue('birthYear', String(date.getFullYear()));
      setValue('birthMonth', months[date.getMonth()]?.name);
      setValue('birthDay', String(date.getDate()));
    }
  }, [userProfile]);

  const onSubmit: SubmitHandler<EditProfileInputs & TCustomFields> = async (data) => {
    const month = months.findIndex(({ name }) => name === data.birthMonth);
    const birthdate = new Date(Date.UTC(+data.birthYear, month, +data.birthDay));
    if (!userId) return;
    if (isDateError(birthdate, dataSettings)) return;

    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (
        acc: Partial<
          Omit<TUserProfile, 'picture'> & { picture: File | string | null } & TCustomFields
        >,
        field,
      ) => {
        if (field === 'birthDay' || field === 'birthYear' || field === 'birthMonth') {
          return acc;
        }

        if (field === 'picture') {
          acc.picture = data.picture;
          return acc;
        }

        acc[field] = data[field];

        return acc;
      },
      {},
    );

    if (birthdate) payload.birthdate = (birthdate as unknown as Date).toISOString();

    if ('login' in dirtyFields && userProfile.login !== data.login) {
      const isAvailable = await checkIdentifier(data.login);
      if (isAvailable) {
        setError('login', {
          type: 'api',
          message: 'Уже существует',
        });
        return;
      }
    }

    const response = await updateUser(payload, userId);
    if (response?.ok) {
      const profile = await getUserProfile();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);

        const removedEmptyCustomFields = Object.keys(payload).reduce((acc: TCustomFields, key) => {
          if (CUSTOM_USER_FIELDS[key] && payload[key] === '') acc[key] = '';
          return acc;
        }, {});

        dispatch(setUserProfile({ ...convertedProfile, ...removedEmptyCustomFields }));
      }
      navigate('/profile');
      dispatch(setIsChangesUnsaved(false));
    } else {
      const responseJson = await response?.json();
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: responseJson?.message,
          timestamp: new Date().toString(),
        }),
      );
    }
  };

  const relocation = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setIsChangesUnsaved(false));
    navigate(componentPath);
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={
          isChangesUnsaved
            ? () => {
                dispatch(setIsModalOpen(true));
                dispatch(setComponentPath('/profile'));
              }
            : () => {
                dispatch(setComponentPath('/profile'));
                navigate(componentPath);
              }
        }
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles['create-client-form']}>
          <Typography
            className={clsx('font-golos', 'text-24-medium', 'color-0B1641', styles.title)}
          >
            Редактировать профиль
          </Typography>
          <ProfileFields userProfile={userProfile} />
          {/* #427 
        <div className={styles.divider} /> 
        <div className={styles['padding-wrapper']}>
          <div className={styles['switch-wrapper']}>
            <div>
              <Typography
                className={clsx('font-golos', 'text-17-regular', 'color-0B1641', styles.subtitle)}
              >
                Публичность
              </Typography>
              <Typography style={{ width: 420 }} className={clsx('text-14', 'color-858BA0')}>
                Ваши публичное имя, id и фото профиля станут видны в поиске другим пользователям
                {PROJECT_NAME}
              </Typography>
            </div>
            <Switch
              value={isPublic}
              onChange={() => setIsPublic((isPublic) => !isPublic)}
              disableRipple
            />
          </div>
        </div> */}
        </form>
      </FormProvider>
      <Modal open={isModalOpen} onClose={() => dispatch(setIsModalOpen(false))}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="custom"
              color="secondary"
              onClick={() => dispatch(setIsModalOpen(false))}
              style={{ marginRight: 24 }}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                relocation();
              }}
              variant="custom"
              color="primary"
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const EditProfile = connect(mapStateToProps)(EditProfileComponent);
