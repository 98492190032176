import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL, CLIENT_ID } from '../../constants';
import { getAccessToken } from '../../service/auth';
import { TApplication, TClient } from './client';
import { TCustomFields, TUserProfile } from '../userSlice';
import { getObjectKeys } from '../../helpers';

export type TUserFieldsToUpdate = Pick<
  TUserProfile,
  'birthdate' | 'email' | 'nickname' | 'phone_number' | 'given_name' | 'family_name'
> & {
  picture?: File | string | null;
  user_id: string;
} & TCustomFields;

const userParamsToFormData = (
  requestParams: Partial<
    Omit<TUserProfile, 'picture'> & {
      picture: File | null;
    } & TCustomFields
  >,
) => {
  try {
    return getObjectKeys(requestParams).reduce((acc, key) => {
      const requestParam = requestParams[key];
      acc.append(key as string, requestParam || '');
      return acc;
    }, new FormData());
  } catch (e) {
    console.log('userParamsToFormData error: ' + (e as Error).message);
  }
};

export const ownerApi = createApi({
  reducerPath: 'ownerApi',
  tagTypes: [
    'Licenses',
    // #807
    //  'AvailableUsersCount'
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/owner`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateUserByOwner: builder.mutation<void, TUserFieldsToUpdate>({
      query: ({ user_id, ...body }) => {
        return {
          url: `/users/${user_id}`,
          method: 'PUT',
          body,
        };
      },
    }),

    addEmailByOwner: builder.mutation<void, { user_id: string | number; email: string }>({
      query: ({ user_id, ...body }) => {
        return {
          url: `/users/email/${user_id}`,
          method: 'POST',
          body,
        };
      },
    }),

    getLicenses: builder.query<string[], void>({
      query: () => `licenses/${CLIENT_ID}`,
      providesTags: [{ type: 'Licenses', id: 'LIST' }],
    }),

    activateLicense: builder.mutation<void, { license: string }>({
      query: (body) => ({
        method: 'POST',
        url: `activate_license/${CLIENT_ID}`,
        body,
      }),
      invalidatesTags: [
        { type: 'Licenses', id: 'LIST' },
        // #807
        // { type: 'AvailableUsersCount', id: 'LIST' },
      ],
    }),

    // #807
    // getAvailableUsersCount: builder.query<number, void>({
    //   query: () => `available_users_count/${CLIENT_ID}`,
    //   transformResponse: ({ count }: { count: number | string }) => {
    //     if (count === 'Infinity') return Infinity;
    //     if (typeof count !== 'number') {
    //       console.log('getAvailableUsersCount error: invalid users count');
    //       return 0;
    //     }
    //     return count;
    //   },
    //   providesTags: [{ type: 'AvailableUsersCount', id: 'LIST' }],
    // }),

    getAllApplications: builder.query<
      TApplication[],
      {
        user_id: string;
        search_string?: string;
        sort_by?: string;
        sort_direction?: string;
        number_of_records?: string;
        number_of_skip?: string;
        last_record_id?: string;
      }
    >({
      query: (body) => {
        return {
          url: `/get_all_clients/${body.user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        };
      },
    }),

    getAllApplicationsCount: builder.query<number, { userId: string; searchString?: string }>({
      query: ({ userId, searchString }) => {
        return {
          url: `/count_all_clients/${userId}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: { search_string: searchString },
        };
      },
    }),

    blockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `block`,
          method: 'PUT',
          body,
        };
      },
    }),

    unblockUsers: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => {
        return {
          url: `unblock`,
          method: 'PUT',
          body,
        };
      },
    }),

    getActiveUsersCount: builder.mutation<
      { active_users_count: number; users_count: number },
      { search_string?: string }
    >({
      query: (body) => ({ url: `active-users-count`, method: 'POST', body }),
    }),

    deleteUsersInfo: builder.mutation<
      { apps: { client: TClient; onlyEditor: boolean }[]; users_ids: number[] },
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({ url: `delete-users-info`, method: 'POST', body }),
    }),

    deleteUsers: builder.mutation<
      { errors: string[] },
      { apps_ids?: string[] | null; delete_apps_with_user?: boolean; checked_ids: number[] }
    >({
      query: (body) => ({ method: 'DELETE', body, url: `users` }),
    }),

    deleteUsersSessions: builder.mutation<
      void,
      { checked_ids?: number[]; all?: boolean; unchecked_ids?: number[]; search_string?: string }
    >({
      query: (body) => ({ method: 'DELETE', body, url: `all_users_sessions` }),
    }),

    createUser: builder.mutation<
      void,
      Partial<Omit<TUserProfile, 'picture'> & { picture: File | null } & TCustomFields>
    >({
      query: (body) => ({ method: 'POST', body: userParamsToFormData(body), url: 'user' }),
    }),

    changePasswordByOwner: builder.mutation<
      void,
      {
        newPassword: string;
        userId: string;
      }
    >({
      query: ({ newPassword, userId }) => {
        return {
          url: `password/${userId}`,
          method: 'PUT',
          body: 'password=' + newPassword,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        };
      },
    }),
  }),
});

export const {
  useUpdateUserByOwnerMutation,
  useAddEmailByOwnerMutation,
  // #807
  // useGetAvailableUsersCountQuery,
  useGetLicensesQuery,
  useActivateLicenseMutation,
  useLazyGetAllApplicationsCountQuery,
  useLazyGetAllApplicationsQuery,
  useBlockUsersMutation,
  useUnblockUsersMutation,
  useGetActiveUsersCountMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useChangePasswordByOwnerMutation,
  useCreateUserMutation,
} = ownerApi;
